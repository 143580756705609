import React, { useState, useEffect } from "react";
import {
    Card,
    CardBody,
    CardTitle,
    Row,
    Col,
    Button,
    FormGroup,
    Label,
    Input
} from "reactstrap";
import Select from "react-select";
import SuspenseComponentText from '../../../views/components/SuspenseComponentText';
import useTimeout from "hooks/useTimeout";
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { getDeviceConfigRadio, postDeviceConfigCommandRadio } from "API";
import { commands } from "variables/commands";

// reactstrap components
const DeviceConfigCommandRemoteColumn = ({ serial }) => {
    const [realizationDateErrorState, setRealizationDateErrorState] = useState('');
    const [realizationDateValue, setRealizationDateValue] = useState(new Date());
    const [singleSelect, setsingleSelect] = useState({
        value: "",
        label: "Single Column",
        isDisabled: true,
        state: null
    });
    const [selectedRadio, setSelectedRadio] = useState(null);
    const [clearRadio, setClearRadio] = useState(false);

    const handleRadioChange = (event) => {
        setSelectedRadio(event.target.value);
    };
    useEffect(() => {
        if (clearRadio === true) {
            setSelectedRadio(null)
            setClearRadio(false)
        }
    }, [clearRadio])
    const [singleSelectOptions, setSingleSelectOptions] = useState([
        {
            value: "",
            label: "Single Column",
            isDisabled: true,
            state: null
        },
        // { value: "Column 1", label: "Column 1", state: true, },
        // { value: "Column 2", label: "Column 2", state: false, },
        // { value: "Column 3", label: "Column 3", state: false, },
        // { value: "Column 4", label: "Column 4", state: false, }
    ]);

    const dataConfigColumn = useQuery('ConfigColumn', () => getDeviceConfigRadio(serial, commands.columnStatus), {
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            setSingleSelectOptions(prevOptions => {
                const updatedOptions = prevOptions.map(option => {
                    const matchingDataOption = data.data.columns.find(dataOption => dataOption.value === option.value);
                    if (matchingDataOption) {
                        return {
                            ...option,
                            label: matchingDataOption.label,
                            state: matchingDataOption.state
                        };
                    }
                    return option;
                });

                const newOptions = data.data.columns.filter(dataOption => {
                    return !prevOptions.some(option => option.value === dataOption.color_name);
                }).map(option => ({
                    value: option.value,
                    label: option.label,
                    state: option.state
                }));
                return [...updatedOptions, ...newOptions];
            });

        }
    });
    const queryClient = useQueryClient()
    const { mutate: mutationConfigCommand, isLoading: mutationConfigCommandLoading, isError: mutationConfigCommandError, isSuccess: mutationConfigCommandSuccess, reset: mutationConfigCommandReset } = useMutation(
        {
            mutationFn: postDeviceConfigCommandRadio,
            onSuccess: () => {
                // Invalidate and refetch
                timeoutClearDataPost();
                queryClient.invalidateQueries({ queryKey: ['Commands'] })
                queryClient.invalidateQueries({ queryKey: ['ConfigColumn'] })

            },
            onError: () => {
                // Invalidate and refetch
                timeoutClearDataPost();
                queryClient.invalidateQueries({ queryKey: ['Commands'] })

            },
        }
    );

    const handleRadioValue = () => {
        if (selectedRadio === 'columnOption1') {
            return 0
        }
        else {
            return 1
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (mutationConfigCommandError === true || mutationConfigCommandLoading === true) {
            return false
        }
        if (realizationDateValue === '' || typeof realizationDateValue !== 'object') {
            setRealizationDateErrorState("has-danger");
            return false
        }
        if (realizationDateErrorState !== '') {
            return false
        }
        if (singleSelect.value === '') {
            setClearRadio(true);
            return false
        }
        mutationConfigCommand({
            serial: serial,
            commandName: singleSelect.value,
            commandValue: handleRadioValue()
        })
    }

    // const handleRealizationDateValue = (date) => {
    //     if (typeof date !== 'object') {
    //         setRealizationDateErrorState('has-danger');
    //         setRealizationDateValue(date);
    //     }
    //     else {
    //         setRealizationDateErrorState('');
    //         setRealizationDateValue(date._d);
    //     }
    // }

    const [timeoutClearDataPost] = useTimeout(() => {
        setClearRadio(true);
        mutationConfigCommandReset();
    }, 3000);


    return (
        <>
            <Col lg="6" md="6">
                <Card className="card-stats">
                    <CardBody>
                        <Row>
                            <Col xs="5">
                                <div className="info-icon text-center icon-primary">
                                    <i className="tim-icons icon-sound-wave" />
                                </div>
                            </Col>
                            <Col xs="7">
                                <div className="numbers">
                                    <p className="card-category">Command</p>
                                    <CardTitle tag="h4">
                                        {`${singleSelect.value === "" ? 'Change Column State' : singleSelect.label} ${singleSelect.state === null ? "" : singleSelectOptions.find((column) => column.value === singleSelect.value).state === false ?  ': Inactive' : ': Active'}`}
                                    </CardTitle>
                                </div>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col md='12' lg='4'>
                                <label>Column Select</label>
                                {/* <FormGroup className={realizationDateErrorState}>
                                    <ReactDatetime
                                        inputProps={{
                                            className: "form-control",
                                            placeholder: "Datetime Picker Here",
                                        }}
                                        timeFormat={"HH:mm"}
                                        dateFormat={"YYYY-MM-DD"}
                                        value={realizationDateValue}
                                        onChange={handleRealizationDateValue}
                                    />
                                    {realizationDateErrorState === "has-danger" ? (
                                        <label className="error">
                                            Please enter a valid datetime YYYY-MM-DD HH:mm.
                                        </label>
                                    ) : null}
                                </FormGroup> */}

                                <Select
                                    className="react-select info"
                                    classNamePrefix="react-select"
                                    name="singleSelect"
                                    value={singleSelect}
                                    onChange={(value) => setsingleSelect(value)}
                                    options={singleSelectOptions}
                                    placeholder="Select Column"
                                />

                            </Col>
                            <Col md='12' lg='4' sm="12" className="flex-el">
                                <FormGroup check inline className="form-check-radio">
                                    <Label check className="label-has-danger">
                                        <Input
                                            value="columnOption1"
                                            checked={selectedRadio === "columnOption1"}
                                            id="columnIdOption1"
                                            name="columnNameOption"
                                            type="radio"
                                            onChange={handleRadioChange}
                                        />
                                        <span className="form-check-sign radio-error" />
                                        Disable
                                    </Label>
                                </FormGroup>
                                <FormGroup check inline className="form-check-radio">
                                    <Label check className="label-has-success">
                                        <Input
                                            value="columnOption2"
                                            checked={selectedRadio === "columnOption2"}
                                            id="columnIdOption2"
                                            name="columnNameOption"
                                            type="radio"
                                            onChange={handleRadioChange}
                                        />
                                        <span className="form-check-sign" />
                                        Enable
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col md='12' lg='4' className="date-submit-center">
                                <div className='submit-button-row'>
                                    <Button className="btn-fill" disabled={singleSelect.value === '' || selectedRadio === null ? true : false} color={mutationConfigCommandError ? "danger" : mutationConfigCommandSuccess ? "success" : 'info'} type="submit"
                                        onClick={(event) => handleSubmit(event)}
                                    >
                                        {mutationConfigCommandLoading ? <SuspenseComponentText /> :
                                            mutationConfigCommandError ? (
                                                <div>
                                                    Failure <i className="tim-icons icon-simple-remove" />
                                                </div>
                                            ) : mutationConfigCommandSuccess ? (
                                                <div>
                                                    Success <i className="tim-icons icon-check-2" />
                                                </div>
                                            ) :
                                                `Submit`
                                        }
                                    </Button>
                                </div>
                            </Col>

                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </>
    )
}

export default DeviceConfigCommandRemoteColumn;