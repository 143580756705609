import React, { Suspense, useState } from 'react';
import SuspenseComponent from '../../../views/components/SuspenseComponent';
import ReturnPersonTable from './ReturnPersonTable';
import ReturnPersonSubmitDateRange from './ReturnPersonSubmitDateRange';
import ReturnPersonSubmitDevices from './ReturnPersonSubmitDevices';
import { getDeviceReturnPersonByRange } from "API";
import { useMutation, useQueryClient, } from 'react-query'
import { Row, Col } from "reactstrap";
const ReturnPerson = (props) => {
    const [valueDateRange, setValueDateRange] = useState([new Date(new Date().setDate(new Date().getDate() - 7)), new Date()]);

    const [loading, setLoading] = useState(null);
    const queryClient = useQueryClient()
    const handleSubmit = () => {
        if (valueDateRange !== null) {
            if (valueDateRange[0] instanceof Date && valueDateRange[1] instanceof Date) {
                setLoading(true);
                mutation({ startRange: valueDateRange[0], endRange: valueDateRange[1] })
            }
        }
    }

    const { mutate: mutation } = useMutation(
        {
            mutationFn: getDeviceReturnPersonByRange,
            onSuccess: async (newData) => {

                setLoading(false);
                queryClient.setQueryData("ReturnPersonData", newData);
            }
        }
    );

    return (
        <>
            <div className="content">
                <Row>
                    <Col lg="3">
                        <h4>
                            Return Person
                        </h4>
                    </Col>
                </Row>
                <Row>
                    <ReturnPersonSubmitDateRange valueDateRange={valueDateRange} setValueDateRange={setValueDateRange} handleSubmit={handleSubmit} />
                    {/* <ReturnPersonSubmitDevices handleSubmit={handleSubmit} /> */}
                </Row>

                <Suspense fallback={<SuspenseComponent />}>
                    <ReturnPersonTable loading={loading} valueDateRange={valueDateRange} />
                </Suspense>
            </div>
        </>
    );
};

export default ReturnPerson;
