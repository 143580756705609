import React from 'react';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import {
    Card,
    CardBody,
    CardTitle,
    Col,
    CardHeader,
    FormGroup,
    Button
} from "reactstrap";


const RatingsSubmitDateRange = ({ valueDateRange, setValueDateRange,handleSetValueDateRange, handleSubmit, handleResetDateRange }) => {


    return (

        <Col lg='6' md="6" sm="12" >
            <Card>
                <CardHeader>
                    <CardTitle tag="h4">Ratings Range</CardTitle>
                </CardHeader>
                <CardBody>
                    <FormGroup>
                        <DateRangePicker
                            rangeDivider={` : `}
                            className={`form-logs-input-date`}
                            value={valueDateRange}
                            onChange={(valueDateRange) => handleSetValueDateRange(valueDateRange)}
                            format={`y-MM-dd`} />
                    </FormGroup>
                    <div className='submit-button-row'>
                        <Button className="btn-fill" color="primary" type="submit" onClick={() => handleResetDateRange()}>
                            Reset Date Range
                        </Button>
                        <Button className="btn-fill" color="primary" type="submit" onClick={() => handleSubmit()}>
                            Submit
                        </Button>
                    </div>
                </CardBody>
            </Card>
        </Col>

    )
}

export default RatingsSubmitDateRange;