import React, { useState } from 'react';
import {
  Row, Col, Card, CardTitle,
  CardHeader,
  Collapse,
  CardBody,
} from "reactstrap";
import { useParams } from "react-router-dom";
import DeviceDetailsParameter from './DeviceDetailsParameter';
import DeviceDetailsParameterColumn from './DeviceDetailsParameterColumn';
import DeviceDetailsStatus from './DeviceDetailsStatus';
import DeviceDetailsHeartbeat from './DeviceDetailsHeartbeat';
import DeviceBreadcrumbComponent from '../DeviceBreadcrumbComponent';
import { Bar } from "react-chartjs-2";
import { useQuery } from 'react-query';
import { getDeviceLastWeekTransaction } from "API";
// reactstrap components
import { useDataDeviceAccess } from 'contexts/DataDeviceContext';
import useSerialCheck from 'hooks/useSerialCheck';
const DeviceDetails = (props) => {
  const [openedCollapseOne, setopenedCollapseOne] = useState(true);
  const [openedCollapseTwo, setopenedCollapseTwo] = useState(false);
  const [labels, setLabels] = useState([]);
  const [buyRentArray, setBuyRentArray] = useState([]);
  const [swapArray, setSwapArray] = useState([]);
  const [swapPaidArray, setSwapPaidArray] = useState([]);
  const [returnArray, setReturnArray] = useState([]);
  let { serial } = useParams();
  const userDataDeviceAccess = useDataDeviceAccess();
  const options = {
    plugins: {
      title: {
        display: false,
        text: 'Last Week Transactions',
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: false,
      },
      y: {
        stacked: false,
      },
    },
  };

  const dataGetLastWeekTransaction = useQuery('LastWeekTransaction', () => getDeviceLastWeekTransaction(serial), {
    refetchInterval: 60000,
    onSuccess: (data) => {

      const { last_week_sales } = data.data;
      const days = last_week_sales.map((item) => Object.keys(item)[0]);

      // Set labels array
      setLabels(days);

      // Set buyRentArray
      const buyRentValues = last_week_sales.map((item) => item[Object.keys(item)[0]]['buy/rent']);
      setBuyRentArray(buyRentValues);

      // Set swapArray
      const swapValues = last_week_sales.map((item) => item[Object.keys(item)[0]]['swap']);
      setSwapArray(swapValues);

      const swapPaidValues = last_week_sales.map((item) => item[Object.keys(item)[0]]['swap_paid']);
      setSwapPaidArray(swapPaidValues);

      // Set returnArray
      const returnValues = last_week_sales.map((item) => item[Object.keys(item)[0]]['return']);
      setReturnArray(returnValues);

    }
  });


  const data = {
    labels,
    datasets: [
      {
        label: 'Buy/Rent',
        data: buyRentArray,
        backgroundColor: 'rgb(255, 99, 132)',
      },
      {
        label: 'Swap',
        data: swapArray,
        backgroundColor: 'rgb(75, 192, 192)',
      },
      {
        label: 'Paid Swap',
        data: swapPaidArray,
        backgroundColor: 'rgb(145, 85, 190)',
      },
      {
        label: 'Return',
        data: returnArray,
        backgroundColor: 'rgb(53, 162, 235)',
      },
    ],
  };
  const serialFound = useSerialCheck(userDataDeviceAccess, serial);
  return (
    <>
      <div className="content">
        <Row>
          <Col lg="3">
            <h4>
              {serialFound !== true ? null : `${serial} Details`}
            </h4>
          </Col>
          <Col lg="9">
            <DeviceBreadcrumbComponent activePath={'DeviceDetails'} serial={serial} />
          </Col>
        </Row>
        <Row>
          <DeviceDetailsStatus serial={serial} />
          <DeviceDetailsHeartbeat serial={serial} />
        </Row>
        <Row>
          <DeviceDetailsParameterColumn serial={serial} elSize={3} elColor={'warning'} nameParam={'column1'} />
          <DeviceDetailsParameterColumn serial={serial} elSize={3} elColor={'warning'} nameParam={'column2'} />
          <DeviceDetailsParameterColumn serial={serial} elSize={3} elColor={'warning'} nameParam={'column3'} />
          <DeviceDetailsParameterColumn serial={serial} elSize={3} elColor={'warning'} nameParam={'column4'} />
        </Row>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h3">More Details</CardTitle>
              </CardHeader>
              <div
                aria-multiselectable={true}
                className="card-collapse"
                id="accordion"
                role="tablist"
              >
                <Card className="card-plain">
                  <CardHeader role="tab">
                    <a
                      aria-expanded={openedCollapseOne}
                      href="#pablo"
                      data-parent="#accordion"
                      data-toggle="collapse"
                      onClick={(e) => {
                        e.preventDefault();
                        setopenedCollapseOne(!openedCollapseOne);
                      }}
                    >
                      Last Week Transactions{" "}
                      <i className="tim-icons icon-minimal-down" />
                    </a>
                  </CardHeader>
                  <Collapse role="tabpanel" isOpen={openedCollapseOne}>
                    <CardBody className='flex-el-center'>
                      <Col className="" md="9">
                        <Card className="card-chart">
                          <CardBody>
                            <div className="chart-area">
                              <Bar
                                options={options} data={data}
                              />
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </CardBody>
                  </Collapse>
                </Card>
                <Card className="card-plain">
                  <CardHeader role="tab">
                    <a
                      aria-expanded={openedCollapseTwo}
                      href="#pablo"
                      data-parent="#accordion"
                      data-toggle="collapse"
                      onClick={(e) => {
                        e.preventDefault();
                        setopenedCollapseTwo(!openedCollapseTwo);
                      }}
                    >
                      More Parameters{" "}
                      <i className="tim-icons icon-minimal-down" />
                    </a>
                  </CardHeader>
                  <Collapse role="tabpanel" isOpen={openedCollapseTwo}>
                    <CardBody>
                      <Row>
                        <DeviceDetailsParameter serial={serial} elSize={4} elColor={'primary'} nameParam={'client'} />
                        <DeviceDetailsParameter serial={serial} elSize={4} elColor={'primary'} nameParam={'terminal_serial'} />
                        <DeviceDetailsParameter serial={serial} elSize={4} elColor={'primary'} nameParam={'support_phone_number'} />
                      </Row>
                      <Row>
                        <DeviceDetailsParameter serial={serial} elSize={4} elColor={'primary'} nameParam={'column_critical_value'} />
                        <DeviceDetailsParameter serial={serial} elSize={4} elColor={'primary'} nameParam={'columns_max_capacity'} />
                        <DeviceDetailsParameter serial={serial} elSize={4} elColor={'primary'} nameParam={'technical_break'} />
                      </Row>
                      <Row>
                        <DeviceDetailsParameter serial={serial} elSize={6} elColor={'primary'} nameParam={'price'} />
                        {/* <DeviceDetailsParameter serial={serial} elSize={4} elColor={'primary'} nameParam={'price_rent'} /> */}
                        <DeviceDetailsParameter serial={serial} elSize={6} elColor={'primary'} nameParam={'deposit_value'} />
                      </Row>
                    </CardBody>
                  </Collapse>
                </Card>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default DeviceDetails;
