import React from 'react';
import {
    Breadcrumb,
    BreadcrumbItem
} from "reactstrap";

const DeviceBreadcrumbComponent = ({ activePath, serial }) => {
    const devicePaths = [
        { name: 'Details', href: `/user/device/details/${serial}`, activeValue: 'DeviceDetails' },
        { name: 'Sale', href: `/user/device/sale/${serial}`, activeValue: 'DeviceSale' },
        { name: 'Logs', href: `/user/device/logs/${serial}`, activeValue: 'DeviceLogs' },
        { name: 'Info', href: `/user/device/info/${serial}`, activeValue: 'DeviceInfo' },
        { name: 'Config', href: `/user/device/config/${serial}`, activeValue: 'DeviceConfig' },
    ];

    return (
        <div>
            <Breadcrumb>
                {devicePaths.map((path, index) =>
                    path.activeValue === activePath ?
                        <BreadcrumbItem key={index} active>{path.name}</BreadcrumbItem>
                        :
                        <BreadcrumbItem key={index}><a href={path.href}>{path.name}</a></BreadcrumbItem>
                )}
            </Breadcrumb>
        </div>
    )
}

export default DeviceBreadcrumbComponent;