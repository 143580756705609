import React, { Suspense, useState } from 'react';
import SuspenseComponent from '../../../views/components/SuspenseComponent';
import DeviceBreadcrumbComponent from '../DeviceBreadcrumbComponent';
import DeviceSaleDoughnut from './DeviceSaleDoughnut';
import DeviceSaleSubmitDateRange from './DeviceSaleSubmitDateRange';
import { useParams } from "react-router-dom";
import { getDeviceSaleByRange } from "API";
import { useMutation, useQueryClient, } from 'react-query';
import { Row, Col } from "reactstrap";
import useSerialCheck from 'hooks/useSerialCheck';
import { useDataDeviceAccess } from 'contexts/DataDeviceContext';
const DeviceSale = (props) => {
    let { serial } = useParams();
    const userDataDeviceAccess = useDataDeviceAccess();
    const serialFound = useSerialCheck(userDataDeviceAccess, serial);
    const [valueDateRange, setValueDateRange] = useState([new Date(new Date().setDate(new Date().getDate() - 1)), new Date()]);
    const [loading, setLoading] = useState(null);
    const queryClient = useQueryClient()
    const handleSubmit = () => {
        if (valueDateRange !== null) {
            if (valueDateRange[0] instanceof Date && valueDateRange[1] instanceof Date) {
                setLoading(true);
                mutation({ serial: serial, startRange: valueDateRange[0], endRange: valueDateRange[1] })
            }
        }
    }

    const { mutate: mutation } = useMutation(
        {
            mutationFn: getDeviceSaleByRange,
            onSuccess: async (newData) => {
                setLoading(false);
                queryClient.setQueryData("Sale", newData);
            }
        }
    );

    return (
        <>
            <div className="content">
                <Row>
                    <Col lg="3">
                        <h4>
                            {serialFound !== true ? null : `${serial} Sale`}
                        </h4>
                    </Col>
                    <Col lg="9">
                        <DeviceBreadcrumbComponent activePath={'DeviceSale'} serial={serial} />
                    </Col>
                </Row>
                <Row>
                    <DeviceSaleSubmitDateRange valueDateRange={valueDateRange} setValueDateRange={setValueDateRange} handleSubmit={handleSubmit} />
                </Row>
                <Row>
                    <Suspense fallback={<SuspenseComponent />}>
                        <DeviceSaleDoughnut loading={loading} serial={serial} valueDateRange={valueDateRange} />
                    </Suspense>
                </Row>

            </div>
        </>
    );
};

export default DeviceSale;
