import React, { useState } from 'react';
import { getDeviceSale, getDeviceSaleTEST } from "API";
import { useQuery } from 'react-query';
import { Doughnut } from "react-chartjs-2";
import SuspenseComponent from '../../../views/components/SuspenseComponent';
import {
    Card,
    CardBody,
    CardTitle,
    Row,
    Col,
    CardHeader,
} from "reactstrap";
const DeviceSaleDoughnut = ({ serial, valueDateRange, loading }) => {

    const [data, setData] = useState([]);
    const chartExample9 = {
        type: 'doughnut',
        data: {
            labels: Object.keys(data),
            datasets: [{
                label: "Emails",
                data: Object.values(data),
                backgroundColor: ["#00c09d", "#e2e2e2", "#9155be", "#e14eca"],
                borderWidth: 0,
            }]
        },
        options: {
            cutout: '50%',  // Adjust this value to make the doughnut smaller
            plugins: {
                legend: { display: true }, // Set display to true to show the legend
                tooltip: {
                    backgroundColor: "#333",
                    titleFontColor: "#444",
                    bodyFontColor: "#666",
                    bodySpacing: 4,
                    xPadding: 12,
                    mode: "nearest",
                    intersect: false,
                    position: "nearest"
                }
            },
            scales: {
                y: { display: false },
                x: {
                    barPercentage: 1.6,
                    grid: { display: false, borderColor: "rgba(255,255,255,0.1)" },
                    ticks: { display: false }
                }
            }
        }
    };
    const dataSale = useQuery('Sale', () => getDeviceSale(serial, valueDateRange[0], valueDateRange[1]), {
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            setData(data.data)
        }
    });

    const dataSaleTEST = useQuery('SaleTEST', () => getDeviceSaleTEST(serial, valueDateRange[0], valueDateRange[1]), {
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
        }
    });

    return (
        <>
            {
                loading || dataSale.isLoading || dataSale.isFetching ? <SuspenseComponent /> :
                    <Col lg='4' md="6" sm="12" className="">
                        <Card className="card-chart card-chart-pie">
                            <CardHeader>
                                <h5 className="card-category">Sale by Type</h5>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col xs="6">
                                        <div className="chart-area">
                                            <Doughnut
                                                data={chartExample9.data}
                                                options={chartExample9.options}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs="6">
                                        <CardTitle tag="h4">
                                            Values:
                                        </CardTitle>
                                        <p className="category">{data['buy/rent'] !== undefined ? `Buy/Rent : ${data['buy/rent']}` : null}</p>
                                        <p className="category">{data['swap'] !== undefined ? `Swap : ${data['swap']}` : null}</p>
                                        <p className="category">{data['swap_paid'] !== undefined ? `Paid Swap : ${data['swap_paid']}` : null}</p>
                                        <p className="category">{data['return'] !== undefined ? `Return : ${data['return']}` : null}</p>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>


            }
        </>


    )
}

export default DeviceSaleDoughnut;