// import { Navigate } from 'react-router-dom';
// import { useAuth } from "./contexts/AuthContext";

// function PrivateRoute({ children }) {
//     const auth = useAuth();
//     return auth ? children : <Navigate to="/" />;
// }
import UserPrivateRoute from "./UserPrivateRoute";
import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import UserLayout from "layouts/User/User.js";
import AuthLayout from "layouts/Auth/Auth.js";

export default function AppRouter(props) {

    

    return (
        <BrowserRouter>
            <Switch>
                <UserPrivateRoute path="/user">
                    <UserLayout {...props} />
                </UserPrivateRoute>
                <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
                <Redirect from="/" to="/user/dashboard" />
            </Switch>
        </BrowserRouter>
    );
}

