import React, { useState } from "react";
import {
    Card,
    CardBody,
    CardTitle,
    Row,
    Col,
    Button,
    Input
} from "reactstrap";
import SuspenseComponentText from '../../../views/components/SuspenseComponentText';
import useTimeout from "hooks/useTimeout";
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { postDeviceConfigCommand, getDeviceParameter } from "API";
import { commands } from "variables/commands";


const DeviceConfigCommandRemoteCharityName = ({ serial }) => {
    const [currentCharityName, setCurrentCharityName] = useState(null);
    const [charityName, setCharityName] = useState("");

    const dataConfigCharityName = useQuery('CharityName', () => getDeviceParameter(serial, commands.charity_organization), {
        refetchOnWindowFocus: false,
        onSuccess: (data) => {

            setCurrentCharityName(data.data.value)

        }
    });


    const handlePriceChange = (event) => {
        setCharityName(event.target.value);
    };

    const queryClient = useQueryClient()
    const { mutate: mutationConfigCommand, isLoading: mutationConfigCommandLoading, isError: mutationConfigCommandError, isSuccess: mutationConfigCommandSuccess, reset: mutationConfigCommandReset } = useMutation(
        {
            mutationFn: postDeviceConfigCommand,
            onSuccess: () => {
                // Invalidate and refetch
                timeoutClearDataPost();
                queryClient.invalidateQueries({ queryKey: ['Commands'] })
                queryClient.invalidateQueries({ queryKey: ['CharityName'] })


            },
            onError: () => {
                // Invalidate and refetch
                timeoutClearDataPost();
                queryClient.invalidateQueries({ queryKey: ['Commands'] })

            },
        }
    );

    const handleSubmit = (event) => {
        event.preventDefault();
        if (mutationConfigCommandError === true || mutationConfigCommandLoading === true) {
            return false
        }

        if (charityName !== "") {
            mutationConfigCommand({
                serial: serial,
                commandName: commands.charity_organization,
                commandValue: charityName
            })
        } else {
            return false;
        }
    }

    const [timeoutClearDataPost] = useTimeout(() => {
        setCharityName("")
        mutationConfigCommandReset();
    }, 3000);


    return (
        <>
            <Col lg="6" md="6">
                <Card className="card-stats">
                    <CardBody>
                        <Row>
                            <Col xs="3">
                                <div className="info-icon text-center icon-success">
                                    <i className="tim-icons icon-badge" />
                                </div>
                            </Col>
                            <Col xs="9">
                                <div className="numbers">
                                    <p className="card-category">Command</p>
                                    <CardTitle tag="h4">

                                        {currentCharityName === null ? 'Change Charity Name' : `Change Charity Name : ${currentCharityName}`}
                                    </CardTitle>
                                </div>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col md='12' lg='6' className="flex-el-bottom-center">
                                <Input
                                    type="text"
                                    value={charityName}
                                    onChange={handlePriceChange}
                                    minLength={1}
                                    maxLength={50}
                                />
                            </Col>
                            <Col md='12' lg='6' className="date-submit-center">
                                <div className='submit-button-row'>
                                    <Button className="btn-fill" disabled={charityName === "" ? true : false} color={mutationConfigCommandError ? "danger" : mutationConfigCommandSuccess ? "success" : 'info'} type="submit"
                                        onClick={(event) => handleSubmit(event)}
                                    >
                                        {mutationConfigCommandLoading ? <SuspenseComponentText /> :
                                            mutationConfigCommandError ? (
                                                <div>
                                                    Failure <i className="tim-icons icon-simple-remove" />
                                                </div>
                                            ) : mutationConfigCommandSuccess ? (
                                                <div>
                                                    Success <i className="tim-icons icon-check-2" />
                                                </div>
                                            ) :
                                                `Submit`
                                        }
                                    </Button>
                                </div>
                            </Col>

                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </>
    )
}

export default DeviceConfigCommandRemoteCharityName;