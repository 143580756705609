import React, { useState } from 'react';
import SuspenseComponent from '../views/components/SuspenseComponent';
import moment from 'moment';
import {MINUTES_BEFORE_EXPIRED_HEARTBEAT_OF_MACHINE} from './../../src/variables/constants.js'
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
} from "reactstrap";
import ReactTable from "components/ReactTable/ReactTable.js";
import { getAllDevices } from "API";
import { useQuery } from 'react-query'

const Dashboard = () => {
  const [data, setData] = useState([]);

  const dataDevices = useQuery('Dashboard', () => getAllDevices(), {
    onSuccess: (data) => {
      setData(
        data.data?.map((prop, key) => {;
          return {
            id: key,
            dashboardName: prop.name,
            dashboardSerial: prop.serial,
            device_status: prop.status,
            // weekly_sales: prop.weekly_sales,
            daily_sales: prop.daily_sales,
            column1_units: prop.column1_units,
            column2_units: prop.column2_units,
            column3_units: prop.column3_units,
            column4_units: prop.column4_units,
            column1_status: prop.column1_status,
            column2_status: prop.column2_status,
            column3_status: prop.column3_status,
            column4_status: prop.column4_status,
            total_units: prop.total_units,
            total_empty_units: prop.total_empty_units,
            lowest_log_lvl: prop.lowest_log_lvl,
            heartbeat: moment.utc(prop.heartbeat).local().format("YYYY-MM-DD HH:mm:ss"),
            expired: moment().diff(moment.utc(prop.heartbeat), 'minutes') >= MINUTES_BEFORE_EXPIRED_HEARTBEAT_OF_MACHINE ? true : false,
            actions: (
              // we've added some custom button actions
              <div className="actions-right">
                {/* use this button to add a like kind of action */}
                <Button color="info"
                  onClick={() => {
                    // let obj = data.find((o) => o.id === key);
                  }}
                  href={`/user/device/details/${prop.serial}`}
                >
                  Go to
                </Button>
              </div>
            )
          };
        })
      );
    },
    refetchInterval: 30000,
  });
  return (
    <>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Your devices</CardTitle>
              </CardHeader>
              <CardBody>
                {dataDevices.isLoading ? <SuspenseComponent /> :
                  <div className='devices-table'>
                    <ReactTable
                      data={data}
                      filterable
                      resizable={false}
                      columns={[
                        {
                          Header: "Name",
                          accessor: "dashboardName",
                          isNamedPlaceholder: true,
                        },
                        // {
                        //   Header: "Serial",
                        //   accessor: "dashboardSerial",
                        //   isNamedPlaceholder: true,
                        // },
                        {
                          Header: "Status",
                          accessor: "device_status",
                          isNamedPlaceholder: true,
                        },
                        {
                          Header: "Sales",
                          accessor: "daily_sales",
                          isNamedPlaceholder: true,
                        },
                        {
                          Header: "Heartbeat",
                          accessor: "heartbeat",
                          isNamedPlaceholder: true,
                        },
                        {
                          Header: "C1",
                          accessor: "column1_units",
                          isNamedPlaceholder: true,
                        },
                        {
                          Header: "C2",
                          accessor: "column2_units",
                          isNamedPlaceholder: true,
                        },
                        {
                          Header: "C3",
                          accessor: "column3_units",
                          isNamedPlaceholder: true,
                        },
                        {
                          Header: "C4",
                          accessor: "column4_units",
                          isNamedPlaceholder: true,
                        },
                        {
                          Header: "Charged",
                          accessor: "total_units",
                          isNamedPlaceholder: true,
                        },
                        {
                          Header: "Empties",
                          accessor: "total_empty_units",
                          isNamedPlaceholder: true,
                        },
                        {
                          Header: "Actions",
                          accessor: "actions",
                          sortable: false,
                          filterable: false
                        }
                      ]}
                      defaultPheartbeatSize={10}
                      showPaginationTop
                      showPaginationBottom={false}
                      className="-striped -highlight"
                    />
                  </div>
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Dashboard;