import React from 'react'
import { Redirect, Route } from 'react-router'
import UserLayout from "layouts/User/User.js";

export default function UserPrivateRoute({ props }) {
  const Auth = sessionStorage.getItem("token");
  const Guid = sessionStorage.getItem("guid");


  return (
    <Route
      render={(props) => Auth && Guid ?

        <UserLayout {...props} />

        : (
          <Redirect
            to={{
              pathname: "/auth/login",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}