import React, { useState, useEffect } from "react";
import {
    Card,
    CardBody,
    CardTitle,
    Row,
    Col,
    Button,
    FormGroup,
    Input
} from "reactstrap";
import SuspenseComponentText from '../../../views/components/SuspenseComponentText';
import useTimeout from "hooks/useTimeout";
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { postDeviceConfigCommand, getDeviceParameter } from "API";
import { commands } from "variables/commands";
// reactstrap components
const DeviceConfigCommandRemoteSwapPrice = ({ serial }) => {
    const [currentSwapPrice, setCurrentSwapPrice] = useState(null);
    const [swapPrice, setSwapPrice] = useState("");
    const [wrongSwapPrice, setWrongSwapPrice] = useState(true);
    useEffect(() => {
        const numericValue = Number(swapPrice);
        if (numericValue >= 0 && !isNaN(numericValue)) {
            setWrongSwapPrice(false)
        } else {
            setWrongSwapPrice(true)
        }
    }, [swapPrice])

    const dataConfigPrice = useQuery('ConfigSwapPrice', () => getDeviceParameter(serial, commands.priceSwap), {
        refetchOnWindowFocus: false,
        onSuccess: (data) => {

            setCurrentSwapPrice(data.data.value)

        }
    });

    const handleSwapPriceChange = (event) => {
        setSwapPrice(event.target.value);
    };

    const queryClient = useQueryClient()
    const { mutate: mutationConfigCommand, isLoading: mutationConfigCommandLoading, isError: mutationConfigCommandError, isSuccess: mutationConfigCommandSuccess, reset: mutationConfigCommandReset } = useMutation(
        {
            mutationFn: postDeviceConfigCommand,
            onSuccess: () => {
                // Invalidate and refetch
                timeoutClearDataPost();
                queryClient.invalidateQueries({ queryKey: ['Commands'] })
                queryClient.invalidateQueries({ queryKey: ['ConfigSwapPrice'] })


            },
            onError: () => {
                // Invalidate and refetch
                timeoutClearDataPost();
                queryClient.invalidateQueries({ queryKey: ['Commands'] })

            },
        }
    );

    const handleSubmit = (event) => {
        event.preventDefault();
        if (mutationConfigCommandError === true || mutationConfigCommandLoading === true) {
            return false
        }

        const numericValue = Number(swapPrice);

        if (numericValue >= 0 && !isNaN(numericValue)) {
            mutationConfigCommand({
                serial: serial,
                commandName: commands.priceSwap,
                commandValue: swapPrice
            })
        } else {
            return false;
        }
    }

    const [timeoutClearDataPost] = useTimeout(() => {
        setSwapPrice("")
        mutationConfigCommandReset();
    }, 3000);


    return (
        <>
            <Col lg="4" md="4">
                <Card className="card-stats">
                    <CardBody>
                        <Row>
                            <Col xs="3">
                                <div className="info-icon text-center icon-success">
                                    <i className="tim-icons icon-coins" />
                                </div>
                            </Col>
                            <Col xs="9">
                                <div className="numbers">
                                    <p className="card-category">Command</p>
                                    <CardTitle tag="h4">

                                        {currentSwapPrice === null ? 'Change Swap Price' : `Change Swap Price : ${currentSwapPrice}`}
                                    </CardTitle>
                                </div>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col md='12' lg='6' className="flex-el-bottom-center">
                                <Input
                                    type="number"
                                    value={swapPrice}
                                    onChange={handleSwapPriceChange}
                                    min={0}
                                />
                            </Col>
                            <Col md='12' lg='6' className="date-submit-center">
                                <div className='submit-button-row'>
                                    <Button className="btn-fill" disabled={ wrongSwapPrice || swapPrice === "" ? true : false} color={mutationConfigCommandError ? "danger" : mutationConfigCommandSuccess ? "success" : 'info'} type="submit"
                                        onClick={(event) => handleSubmit(event)}
                                    >
                                        {mutationConfigCommandLoading ? <SuspenseComponentText /> :
                                            mutationConfigCommandError ? (
                                                <div>
                                                    Failure <i className="tim-icons icon-simple-remove" />
                                                </div>
                                            ) : mutationConfigCommandSuccess ? (
                                                <div>
                                                    Success <i className="tim-icons icon-check-2" />
                                                </div>
                                            ) :
                                                `Submit`
                                        }
                                    </Button>
                                </div>
                            </Col>

                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </>
    )
}

export default DeviceConfigCommandRemoteSwapPrice;