import React from "react";
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
} from "reactstrap";
import { InfinitySpin } from "react-loader-spinner";
export default function SuspenseComponent(props) {


    return (
        <Row>
            <Col xs={12} md={12}>
                <Card>
                    <CardHeader>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <CardTitle tag="h4">Loading ...</CardTitle>
                            <InfinitySpin
                                width='100'
                                color="#2E6DF5"
                            />
                        </div>
                    </CardHeader>
                    <CardBody>

                    </CardBody>
                </Card>
            </Col>
        </Row>

    )
}