import React, { Suspense, useEffect, useState } from "react";
import SuspenseComponent from "../../components/SuspenseComponent";
import InsightsTable from "./InsightsTable";
import InsightsSubmitDateRange from "./InsightsSubmitDateRange";
import { getInsights } from "API";
import { useMutation, useQueryClient } from "react-query";
import { Row, Col } from "reactstrap";

const Insights = (props) => {
  const [valueDateRange, setValueDateRange] = useState([new Date(new Date().setDate(new Date().getDate() - 7)), new Date(), ]);

  const [loading, setLoading] = useState(null);
  const queryClient = useQueryClient();
  const handleSubmit = () => {
    if (valueDateRange !== null) {
      if ( valueDateRange[0] instanceof Date && valueDateRange[1] instanceof Date) {
        setLoading(true);
        mutation({ startRange: valueDateRange[0], endRange: valueDateRange[1], });
      }
    }
  };

  const { mutate: mutation } = useMutation({
    mutationFn: getInsights,
    onSuccess: async (newData) => {
      setLoading(false);
      queryClient.setQueryData("InsightsData", newData);
    },
  });

  useEffect(() => {
    handleSubmit();
  }, []);

  return (
    <>
      <div className="content">
        <Row>
          <Col lg="3">
            <h4>Insights</h4>
          </Col>
        </Row>
        <Row>
          <InsightsSubmitDateRange
            valueDateRange={valueDateRange}
            setValueDateRange={setValueDateRange}
            handleSubmit={handleSubmit}
          />
        </Row>
        <Suspense fallback={<SuspenseComponent />}>
          <InsightsTable loading={loading} valueDateRange={valueDateRange} />
        </Suspense>
      </div>
    </>
  );
};

export default Insights;
