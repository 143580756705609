import React from 'react';
import {
    Breadcrumb,
    BreadcrumbItem
} from "reactstrap";

const ItemDetailsBreadcrumbComponent = ({ activePath, serial, itemid }) => {
    const devicePaths = [
        { name: 'Details', href: `/user/device/details/${serial}`, activeValue: 'DeviceDetails' },
        { name: 'Items', href: `/user/item/details/${serial}/${itemid}`, activeValue: 'Items' },
    ];

    return (
        <div>
            <Breadcrumb separator=">" className='item-details-breadcrumb'>
                {devicePaths.map((path, index) =>
                    path.activeValue === activePath ?
                        <BreadcrumbItem className='item-details-breadcrumbItem' key={index} active>{path.name}</BreadcrumbItem>
                        :
                        <BreadcrumbItem className='item-details-breadcrumbItem' key={index}><a href={path.href}>{path.name}</a></BreadcrumbItem>
                )}
            </Breadcrumb>
        </div>
    )
}

export default ItemDetailsBreadcrumbComponent;