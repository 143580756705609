import React, { useState, useEffect } from "react";
import {
    Card,
    CardBody,
    CardTitle,
    Row,
    Col,
    Button,
    FormGroup,
    Label,
    Input
} from "reactstrap";
import SuspenseComponentText from '../../../views/components/SuspenseComponentText';
import useTimeout from "hooks/useTimeout";
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { postDeviceConfigCommandRadio , getDeviceConfigRadio } from "API";
import { commands } from "variables/commands";

// reactstrap components
const DeviceConfigCommandRemoteReturn = ({ serial }) => {
    const [realizationDateErrorState, setRealizationDateErrorState] = useState('');
    const [realizationDateValue, setRealizationDateValue] = useState(new Date());
    const [selectedRadio, setSelectedRadio] = useState(null);
    const [clearRadio, setClearRadio] = useState(false);
    const [paramCurrent, setParamCurrent] = useState(null);

    const handleRadioChange = (event) => {
        setSelectedRadio(event.target.value);
    };
    useEffect(() => {
      if(clearRadio === true) {
        setSelectedRadio(null)
        setClearRadio(false)
      }
    }, [clearRadio])
    const queryClient = useQueryClient()
    const { mutate: mutationConfigCommand, isLoading: mutationConfigCommandLoading, isError: mutationConfigCommandError, isSuccess: mutationConfigCommandSuccess, reset: mutationConfigCommandReset } = useMutation(
        {
            mutationFn: postDeviceConfigCommandRadio,
            onSuccess: () => {
                // Invalidate and refetch
                timeoutClearDataPost();
                queryClient.invalidateQueries({ queryKey: ['Commands'] })
                queryClient.invalidateQueries({ queryKey: ['GetConfigReturn'] })
                
            },
            onError: () => {
                // Invalidate and refetch
                timeoutClearDataPost();
                queryClient.invalidateQueries({ queryKey: ['Commands'] })
                
            },
        }
    );

    const dataConfigReturn = useQuery('GetConfigReturn', () => getDeviceConfigRadio(serial, commands.return), {
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            setParamCurrent(data.data.value)

        }
    });

    const handleRadioValue = () => {
        if (selectedRadio === 'returnOption1') {
            return 0
        }
        else {
            return 1
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (mutationConfigCommandError === true || mutationConfigCommandLoading === true) {
            return false
        }
        if (realizationDateValue === '' || typeof realizationDateValue !== 'object') {
            setRealizationDateErrorState("has-danger");
            return false
        }
        if (realizationDateErrorState !== '') {
            return false
        }
        
        mutationConfigCommand({
            serial: serial,
            commandName: commands.return,
            commandValue: handleRadioValue()
        })
    }

    // const handleRealizationDateValue = (date) => {
    //     if (typeof date !== 'object') {
    //         setRealizationDateErrorState('has-danger');
    //         setRealizationDateValue(date);
    //     }
    //     else {
    //         setRealizationDateErrorState('');
    //         setRealizationDateValue(date._d);
    //     }
    // }

    const [timeoutClearDataPost] = useTimeout(() => {
        setClearRadio(true);
        mutationConfigCommandReset();
    }, 3000);


    return (
        <>
            <Col lg="6" md="6" sm="12">
                <Card className="card-stats">
                    <CardBody>
                        <Row>
                            <Col xs="5">
                                <div className="info-icon text-center icon-info">
                                    <i className="tim-icons icon-refresh-01" />
                                </div>
                            </Col>
                            <Col xs="7">
                                <div className="numbers">
                                    <p className="card-category">Command</p>
                                    <CardTitle tag="h4">
                                        Set Return {paramCurrent === null ? "" : paramCurrent === 1 ? ": Enabled" : ': Disabled'}
                                    </CardTitle>
                                </div>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            {/* <Col md='12' lg='4'>
                                <label>Realization date</label>
                                <FormGroup className={realizationDateErrorState}>
                                    <ReactDatetime
                                        inputProps={{
                                            className: "form-control",
                                            placeholder: "Datetime Picker Here",
                                        }}
                                        timeFormat={"HH:mm"}
                                        dateFormat={"YYYY-MM-DD"}
                                        value={realizationDateValue}
                                        onChange={handleRealizationDateValue}
                                    />
                                    {realizationDateErrorState === "has-danger" ? (
                                        <label className="error">
                                            Please enter a valid datetime YYYY-MM-DD HH:mm.
                                        </label>
                                    ) : null}
                                </FormGroup>
                            </Col> */}
                            <Col md='12' lg='8' sm="12">
                                <FormGroup check inline className="form-check-radio">
                                    <Label check className="label-has-danger">
                                        <Input
                                            value="returnOption1"
                                            checked={selectedRadio === "returnOption1"}
                                            id="returnIdOption1"
                                            name="returnNameOption"
                                            type="radio"
                                            onChange={handleRadioChange}
                                        />
                                        <span className="form-check-sign radio-error" />
                                        Disable
                                    </Label>
                                </FormGroup>
                                <FormGroup check inline className="form-check-radio">
                                    <Label check className="label-has-success">
                                        <Input
                                            value="returnOption2"
                                            checked={selectedRadio === "returnOption2"}
                                            id="returnIdOption2"
                                            name="returnNameOption"
                                            type="radio"
                                            onChange={handleRadioChange}
                                        />
                                        <span className="form-check-sign" />
                                        Enable
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col md='12' lg='4' sm="12" className="date-submit-center">
                                <div className='submit-button-row'>
                                    <Button className="btn-fill" disabled={selectedRadio === null ? true : false} color={mutationConfigCommandError ? "danger" : mutationConfigCommandSuccess ? "success" : 'info'} type="submit"
                                        onClick={(event) => handleSubmit(event)}
                                    >
                                        {mutationConfigCommandLoading ? <SuspenseComponentText /> :
                                            mutationConfigCommandError ? (
                                                <div>
                                                    Failure <i className="tim-icons icon-simple-remove" />
                                                </div>
                                            ) : mutationConfigCommandSuccess ? (
                                                <div>
                                                    Success <i className="tim-icons icon-check-2" />
                                                </div>
                                            ) :
                                                `Submit`
                                        }
                                    </Button>
                                </div>
                            </Col>

                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </>
    )
}

export default DeviceConfigCommandRemoteReturn;