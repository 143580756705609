import React from 'react';
import Select from "react-select";
import SuspenseComponent from '../../../views/components/SuspenseComponent';
import ReactTable from "components/ReactTable/ReactTableConfig.js";

import NotificationAlert from "react-notification-alert";

import {
    Card,
    CardBody,
    CardTitle,
    Row,
    Col,
    CardHeader,
    Button,
    Modal,
    ModalBody,
    Label,
    FormGroup,
    Input,
    Form
} from "reactstrap";



const ConfigurationTable = ({ loading, setCurrentPassword, setmultipleEditSelect, optionsGroup, multipleEditSelect, dataItemGroups, setCurrentName, responseData, setCurrentId, currentId, requiredPasswordState, currentPassword,
    currentName, data, setRepeatedState, stateFunctions, modalClassic, repeatedState, requiredState, closeModalClassic, notificationAlertRef, saveAndCloseModal }) => {

    // function that verifies if a string has a given length or not
    const verifyLength = (value, length) => {
        if (value.length >= length) {
            return true;
        }
        return false;
    };

    const checkMatches = (itemGroupName) => {
        const matchFound = responseData.data.item_groups.some(
            (group) => group.name === itemGroupName && group.id !== currentId
        );
        return matchFound
    };

    const change = (event, stateName, type, stateNameEqualTo, maxValue) => {
        const value = event.target.value;
        const newValue = value.replace(/\s/g, ''); // Remove spaces from the value
        switch (type) {
            case "length":
                setCurrentName(newValue)
                if (verifyLength(newValue, stateNameEqualTo)) {
                    if (checkMatches(newValue)) {
                        setRepeatedState(true);
                        stateFunctions["set" + stateName + "State"]("has-danger");
                    }
                    else {
                        setRepeatedState(false);
                        stateFunctions["set" + stateName + "State"]("has-success");
                    }
                } else {
                    setRepeatedState(false);
                    stateFunctions["set" + stateName + "State"]("has-danger");
                }
                break;
            case "lengthPassword":
                setCurrentPassword(newValue)
                if (verifyLength(newValue, stateNameEqualTo)) {
                    stateFunctions["set" + stateName + "State"]("has-success");
                } else {
                    stateFunctions["set" + stateName + "State"]("has-danger");
                }
                break;
            default:
                break;
        }
        stateFunctions["set" + stateName](newValue);
    };


    return (


        <Row>
            <Modal isOpen={modalClassic} toggle={closeModalClassic}>
                <div className="modal-header modal-back justify-content-center">
                    <button
                        aria-hidden={true}
                        className="close modal-higher-close"
                        data-dismiss="modal"
                        type="button"
                        onClick={closeModalClassic}
                    >
                        <i className="tim-icons icon-simple-remove" />
                    </button>
                    <h6 className="title title-up title-down title-white">Edit username</h6>
                </div>
                <ModalBody className="text-center modal-back">
                    <Form action="/" className="form-horizontal" method="get">
                        <Row>
                            <Label sm="3">Username</Label>
                            <Col sm="9">
                                <FormGroup className={requiredState}>
                                    <Input
                                        value={currentName}
                                        name="required"
                                        type="text"
                                        onChange={(e) => change(e, "required", "length", 1)}
                                    />
                                    {requiredState === "has-danger" && currentName === "" ? (
                                        <label className="error">
                                            This field is required.
                                        </label>
                                    ) : null}
                                    {requiredState === "has-danger" && repeatedState === true ? (
                                        <label className="error">
                                            Repeated field.
                                        </label>
                                    ) : null}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Label sm="3">Password</Label>
                            <Col sm="9">
                                <FormGroup className={requiredPasswordState}>
                                    <Input
                                        value={currentPassword}
                                        name="requiredPassword"
                                        type="text"
                                        onChange={(e) => change(e, "requiredPassword", "lengthPassword", 1)}
                                    />
                                    {requiredPasswordState === "has-danger" && currentPassword === "" ? (
                                        <label className="error">
                                            This field is required.
                                        </label>
                                    ) : null}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Label sm="3">Access devices</Label>
                            <Col sm="9">
                                <Select
                                    className="react-select info"
                                    classNamePrefix="react-select"
                                    placeholder="Choose devices"
                                    name="multipleSelect"
                                    closeMenuOnSelect={false}
                                    isMulti
                                    value={multipleEditSelect}
                                    onChange={(value) => setmultipleEditSelect(value)}
                                    options={optionsGroup}
                                />
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <div className="modal-footer modal-back">
                    <Button
                        color="danger"
                        data-dismiss="modal"
                        type="button"
                        onClick={closeModalClassic}
                    >
                        Close
                    </Button>
                    <Button
                        color="success"
                        type="button"
                        onClick={saveAndCloseModal}
                        disabled={requiredState === "has-danger" || requiredPasswordState === "has-danger" || currentName === "" || currentPassword === "" || repeatedState === true ? true : false}
                    >
                        Save
                    </Button>
                </div>
            </Modal>
            <div className="rna-container">
                <NotificationAlert ref={notificationAlertRef} />
            </div>
            <Col xs={12} md={12}>
                <Card>
                    <CardHeader>
                        <CardTitle tag="h4">Service Usernames</CardTitle>
                    </CardHeader>
                    <CardBody>

                        {
                            loading ? <SuspenseComponent /> :
                                <div className='item-groups-table'>

                                    <ReactTable
                                        data={data}
                                        filterable
                                        resizable={false}
                                        columns={[
                                            {
                                                Header: "Username",
                                                accessor: "itemUsername"
                                            },
                                            {
                                                Header: "Password",
                                                accessor: "itemPassword"
                                            },
                                            {
                                                Header: "Actions",
                                                accessor: "actions",
                                                sortable: false,
                                                filterable: false
                                            }

                                        ]}

                                        defaultPheartbeatSize={10}
                                        showPaginationTop
                                        showPaginationBottom={false}
                                        className="-striped -highlight"

                                    />
                                </div>
                        }


                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default ConfigurationTable;