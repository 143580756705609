import moment from 'moment'
import {MINUTES_BEFORE_EXPIRED_HEARTBEAT_OF_MACHINE} from './../../src/variables/constants.js'
export default function useHeartbeatCheck(date) {
    if (date === null) {
        return '-';
    }
    const then = new Date(date);

    const isOffline = moment().diff(moment.utc(then), 'minutes') >= MINUTES_BEFORE_EXPIRED_HEARTBEAT_OF_MACHINE ? true : false;

    if (isOffline) {
        return 'Offline';
    } else {
        return 'Online';
    }

}