import React, { Suspense, useState } from 'react';
import SuspenseComponent from '../../../views/components/SuspenseComponent';
import ConfigurationTable from './ConfigurationTable';
import { Button } from "reactstrap";
import { getItemGroups } from "API";
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { postDeviceItemDelete, postItemGroupEdit, postItemGroupAdd } from "API";
import ConfigurationForm from './ConfigurationForm';
import useTimeout from "hooks/useTimeout";
const dataTest = {
    item_groups:
        [{
            id: 1,
            name: 'Item Group 1',
        },
        {
            id: 2,
            name: 'Item Group 2',
        },
        {
            id: 3,
            name: 'Item Group 3',
        }]
}

const Configuration = (props) => {
    const notificationAlertRef = React.useRef(null);
    const [data, setData] = useState([]);
    const [responseData, setResponseData] = useState([]);
    const [modalClassic, setModalClassic] = useState(false);
    const [repeatedState, setRepeatedState] = useState(false);
    const [addRepeatedState, setAddRepeatedState] = useState(false);
    const [currentId, setCurrentId] = useState(null);
    const [currentName, setCurrentName] = useState("");
    const [currentPassword, setCurrentPassword] = useState("");
    const [requiredState, setrequiredState] = useState("");
    const [requiredPasswordState, setrequiredPasswordState] = useState("");
    const [required, setrequired] = useState("");
    const [requiredPassword, setrequiredPassword] = useState("");
    const [loading, setLoading] = useState(null);
    const [currentAddName, setCurrentAddName] = useState("");
    const [currentAddPassword, setCurrentAddPassword] = useState("");
    const [multipleAddSelect, setmultipleAddSelect] = useState(null);
    const [multipleEditSelect, setmultipleEditSelect] = useState(null);
    const [optionsGroup, setOptionsGroup] = useState([
        {
            value: "",
            label: " Multiple Item Groups",
            isDisabled: true
        },]);

    const convertObjectToArray = (obj) => {
        if (obj === null) { return []; }
        if (obj.length === 0) { return []; }
        return obj.map(item => parseInt(item.value));
    }

    const convertObjectToMultipleSelectArray = (obj) => {
        if (obj.length === 0) { return null; }
        if (obj.length === 1) {
            let isEmpty = Object.keys(obj[0]).length === 0
            if (isEmpty === true) {
                return null
            }
        }
        return obj.map(itemGroup => ({
            value: itemGroup.item_group_id.toString(),
            label: itemGroup.item_group_name
        }));;
    }

    const dataDevicesForAccess = useQuery('dataDevicesForAccess', () => getItemGroups(), { // dodac url
        staleTime: 20000, // 20 seconds,
        onSuccess: (data) => {
            closeModalClassic();
            setOptionsGroup([
                { value: '', label: ' Multiple Item Groups', isDisabled: true },
                ...data.data?.item_groups?.map((group) => ({
                    value: group.id.toString(),
                    label: group.name
                }))
            ])
        },
        onError: () => {
            closeModalClassic();
        },
        onSettled: () => {
            closeModalClassic();
        }
    });

    const dataItemGroups = useQuery('ItemGroups', () => getItemGroups(), {
        staleTime: 20000, // 20 seconds,
        onSuccess: (data) => {
            closeModalClassic();
            setResponseData(data);
            setData(
                // dataTest.data?.map((prop, key) => {
                data.data.item_groups?.map((prop, key) => {
                    return {
                        id: prop.id,
                        itemUsername: prop.name,
                        itemPassword: prop.name,
                        actions: (
                            // we've added some custom button actions
                            <div className="actions-right">
                                <Button color="info"
                                    onClick={() => toggleModalClassic(prop.id, prop.name, prop.name, prop.access_details || [])}
                                >
                                    Edit
                                </Button>
                                {/* <Button color="danger"
                                    onClick={() => handleDelete(prop.id)}
                                >
                                    Delete
                                </Button> */}

                            </div>
                        )
                    };
                })
            );
        },
        onError: () => {
            closeModalClassic();
        },
        onSettled: () => {
            closeModalClassic();

        }
    });

    const closeModalClassic = () => {
        stateFunctions["setrequiredState"]("")
        stateFunctions["setrequiredPasswordState"]("")
        setCurrentId(null);
        setCurrentName("");
        setCurrentPassword("");
        setmultipleEditSelect(null);
        setRepeatedState(false);
        setModalClassic(false);
    };

    const stateFunctions = {
        // register form
        setrequired: (value) => setrequired(value),
        setrequiredState: (value) => setrequiredState(value),
        setrequiredPassword: (value) => setrequiredPassword(value),
        setrequiredPasswordState: (value) => setrequiredPasswordState(value),
    };


    const queryClient = useQueryClient()
    const { mutate: mutationItemGroupDelete, isLoading: mutationItemGroupDeleteLoading, isError: mutationItemGroupDeleteError, isSuccess: mutationItemGroupDeleteSuccess, reset: mutationItemGroupDeleteReset } = useMutation(
        {
            mutationFn: postDeviceItemDelete,
            onSuccess: (data) => {
                // Invalidate and refetch

                notify("bc", 2, 2)
                timeoutClearDataPost();
                queryClient.invalidateQueries({ queryKey: ['ItemGroups'] })
            },
            onError: () => {

                notify("bc", 3, 1)
                // Invalidate and refetch
                timeoutClearDataPost();
            },
        }
    );

    const { mutate: mutationItemGroupEdit, isLoading: mutationItemGroupEditLoading, isError: mutationItemGroupEditError, isSuccess: mutationItemGroupEditSuccess, reset: mutationItemGroupEditReset } = useMutation(
        {
            mutationFn: postItemGroupEdit,
            onSuccess: (data) => {
                // Invalidate and refetch

                notify("bc", 2, 3)
                timeoutClearDataPostEdit();
                queryClient.invalidateQueries({ queryKey: ['ItemGroups'] })
            },
            onError: () => {

                notify("bc", 3, 4)
                // Invalidate and refetch
                timeoutClearDataPostEdit();
            },
        }
    );

    const { mutate: mutationItemGroupAdd, isLoading: mutationItemGroupAddLoading, isError: mutationItemGroupAddError, isSuccess: mutationItemGroupAddSuccess, reset: mutationItemGroupAddReset } = useMutation(
        {
            mutationFn: postItemGroupAdd,
            onSuccess: (data) => {
                // Invalidate and refetch

                notify("bc", 2, 5)
                timeoutClearDataPostAdd();
                queryClient.invalidateQueries({ queryKey: ['ItemGroups'] })
            },
            onError: () => {

                notify("bc", 3, 6)
                // Invalidate and refetch
                timeoutClearDataPostAdd();
            },
        }
    );

    const notify = (place, color, message) => {
        var type;
        var messageType;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }
        switch (message) {
            case 1:
                messageType = (<div>
                    <div>
                        Unable to delete current item group. <b>Try again later...</b>
                    </div>
                </div>);
                break;
            case 2:
                messageType = (<div>
                    <div>
                        Successfully deleted <b>item group</b>.
                    </div>
                </div>);
                break;
            case 3:
                messageType = (<div>
                    <div>
                        Successfully edited <b>item group</b>.
                    </div>
                </div>);
                break;
            case 4:
                messageType = (<div>
                    <div>
                        Unable to edit current item group. <b>Try again later...</b>
                    </div>
                </div>);
                break;
            case 5:
                messageType = (<div>
                    <div>
                        Successfully added <b>item group</b>.
                    </div>
                </div>);
                break;
            case 6:
                messageType = (<div>
                    <div>
                        Unable to add item group. <b>Try again later...</b>
                    </div>
                </div>);
                break;
            default:
                break;
        }
        var options = {};
        options = {
            place: place,
            message: messageType,
            type: type,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        notificationAlertRef.current.notificationAlert(options);
    };

    const [timeoutClearDataPost] = useTimeout(() => {
        mutationItemGroupDeleteReset();
    }, 3000);

    const [timeoutClearDataPostEdit] = useTimeout(() => {
        mutationItemGroupEditReset();
    }, 3000);

    const [timeoutClearDataPostAdd] = useTimeout(() => {
        mutationItemGroupAddReset();
    }, 3000);

    const handleDelete = (itemId) => {
        if (mutationItemGroupDeleteError === true || mutationItemGroupDeleteLoading === true) {
            return false
        }

        mutationItemGroupDelete({
            itemId: itemId
        })

    }

    const handleEdit = (itemId, itemName, itemPassword, itemGroupArr) => {
        if (mutationItemGroupEditError === true || mutationItemGroupEditLoading === true) {
            return false
        }
        mutationItemGroupEdit({
            
            itemId: itemId,
            itemName: itemName,
            itemPassword: itemPassword,
            itemGroupArr: itemGroupArr,
        })

    }

    const handleAdd = (itemName, itemPassword, itemGroupArr) => {
        if (mutationItemGroupAddError === true || mutationItemGroupAddLoading === true) {
            return false
        }
        mutationItemGroupAdd({
            
            itemName: itemName,
            itemPassword: itemPassword,
            itemGroupArr: itemGroupArr,
        })

    }

    const DataCheck = (inputId, inputString) => {
        const { data } = dataDevicesForAccess;
        let isDuplicate = false;
        if (data.length > 0) {
            isDuplicate = data.some(({ id, item_group_name }) => id !== inputId && item_group_name === inputString);
        }
        return isDuplicate;
    };

    const saveAndCloseModal = () => {
        let verifyRepeat = DataCheck(currentId, currentName);
        if (verifyRepeat) { return false; }
        else {
            const currentEditGroupArr = convertObjectToArray(multipleEditSelect);
            handleEdit(currentId, currentName, currentPassword, currentEditGroupArr);
        }
        closeModalClassic();
    };

    const toggleModalClassic = (id, name, password, device_details) => {
        setCurrentId(id)
        setCurrentName(name)
        setCurrentPassword(password);
        setmultipleEditSelect(convertObjectToMultipleSelectArray(device_details));
        setModalClassic(true);
    };
    return (
        <>
            <div className="content">
                <Suspense fallback={<SuspenseComponent />}>
                    <ConfigurationTable loading={loading} 
                        setCurrentName={setCurrentName}
                        setCurrentPassword={setCurrentPassword}
                        setCurrentId={setCurrentId}
                        currentName={currentName}
                        currentPassword={currentPassword}
                        currentId={currentId}
                        data={data}
                        responseData={responseData}
                        stateFunctions={stateFunctions}
                        notificationAlertRef={notificationAlertRef}
                        modalClassic={modalClassic}
                        closeModalClassic={closeModalClassic}
                        saveAndCloseModal={saveAndCloseModal}
                        requiredState={requiredState}
                        requiredPasswordState={requiredPasswordState}
                        repeatedState={repeatedState}
                        setRepeatedState={setRepeatedState}
                        multipleEditSelect={multipleEditSelect}
                        dataItemGroups={dataItemGroups}
                        setmultipleEditSelect={setmultipleEditSelect}
                        optionsGroup={optionsGroup}
                    />
                </Suspense>
                <Suspense fallback={<SuspenseComponent />}>
                    <ConfigurationForm
                        currentAddName={currentAddName}
                        currentAddPassword={currentAddPassword}
                        setCurrentAddName={setCurrentAddName}
                        setCurrentAddPassword={setCurrentAddPassword}
                        handleAdd={handleAdd}
                        setAddRepeatedState={setAddRepeatedState}
                        addRepeatedState={addRepeatedState}
                        responseData={responseData}
                        multipleAddSelect={multipleAddSelect}
                        setmultipleAddSelect={setmultipleAddSelect}
                        optionsGroup={optionsGroup}
                        convertObjectToArray={convertObjectToArray}
                    />
                </Suspense>
            </div>
        </>
    );
};

export default Configuration;
