import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Authorize, GetDevices } from "API";
import useTimeout from "hooks/useTimeout";
import { useMutation } from "react-query";
import { useDataDeviceAccessUpdate } from "../../contexts/DataDeviceContext";
import { useQuery } from "react-query";
import LoginForm from "./LoginForm";

const Login = () => {
  const [state, setState] = useState({});
  const [authParam, setAuthParam] = useState("");
  const [guidParam, setGuidParam] = useState("");
  const [isDeviceAccessError, setIsDeviceAccessError] = useState(false);
  const history = useHistory();
  const mutation = useMutation(Authorize);
  const changeDataDeviceAccess = useDataDeviceAccessUpdate();
  const { data: dataDevicesAccess, refetch: dataDevicesAccessRefetch } = useQuery(['dataDevicesAccess', authParam], GetDevices, {
    onSuccess: (data) => {

      changeDataDeviceAccess(data.data.serial_list);
      history.push('/user/dashboard');
    },
    onError: () => {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("guid");
      setAuthParam("");
      setGuidParam("");
      setIsDeviceAccessError(true);
      timeoutResponseNull();
    },
    manual: true,
    enabled: false,
  });


  useEffect(() => {
    if (authParam !== '') {
      dataDevicesAccessRefetch();
    }
  }, [authParam])

  useEffect(() => {
    document.body.classList.toggle("login-page");
    return function cleanup() {
      document.body.classList.toggle("login-page");
    };
  });

  const [timeoutResponseNull] = useTimeout(() => {
    setState({ ...state, responseError: null })
    setIsDeviceAccessError(false);
  }, 3000);


  const handleLogin = (user) => {
    if (mutation.isLoading) {
      return false
    }
    mutation.mutate({ username: user.username, password: user.password },
      {
        onSuccess: (res) => {

          if (res.data) {
            sessionStorage.setItem("token", JSON.stringify(res.data.Authentication.token));
            sessionStorage.setItem("guid", JSON.stringify(res.data.Authentication.GUID));
            setAuthParam(res.data.Authentication.token);
            setAuthParam(res.data.Authentication.GUID);
          }
        },
        onError: (err) => {

          if (err.response.status === 0) {
            setState({ ...user, responseError: 'NONETWORK' })
          }
          else {
            setState({ ...user, responseError: 'ERROR' })
          }
        },
      })
    timeoutResponseNull();
  }
  return (
    <>
      <div className="content">
        <div className="main_bg_video_wrapper">
          {/* <video autoPlay playsInline muted loop id="video_bg" >
            <source src={require("assets/video/background.mp4")} type="video/mp4" />
          </video> */}
          {/* <img id="video_bg" src="{{ url_for('static', filename='assets/img/main_image.jpeg') }}" alt="Main Image"></img> */}
          <img id="video_bg" alt="main" src={require("assets/img/main_image.jpeg")} />
          
          <LoginForm
            handleLogin={handleLogin}
            isLoading={mutation.isLoading}
            isError={state.responseError}
          />
        </div>
      </div>
    </>
  );
};

export default Login;