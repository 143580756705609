import React, { useState } from "react";
import {
    Card,
    CardBody,
    CardTitle,
    Row,
    Col,
    Button
} from "reactstrap";
import SuspenseComponentText from '../../../views/components/SuspenseComponentText';
import useTimeout from "hooks/useTimeout";
import { useMutation, useQueryClient } from 'react-query';
import { postDeviceConfigCommandReboot } from "API";
import { commands } from "variables/commands";

// reactstrap components
const DeviceConfigCommandReboot = ({ serial }) => {
    const [realizationDateErrorState, setRealizationDateErrorState] = useState('');
    const [realizationDateValue, setRealizationDateValue] = useState(new Date());
    const queryClient = useQueryClient()
    const { mutate: mutationConfigCommand, isLoading: mutationConfigCommandLoading, isError: mutationConfigCommandError, isSuccess: mutationConfigCommandSuccess, reset: mutationConfigCommandReset } = useMutation(
        {
            mutationFn: postDeviceConfigCommandReboot,
            onSuccess: () => {
                // Invalidate and refetch
                timeoutClearDataPost();
                queryClient.invalidateQueries({ queryKey: ['Commands'] })
            },
            onError: () => {
                // Invalidate and refetch
                timeoutClearDataPost();
                queryClient.invalidateQueries({ queryKey: ['Commands'] })
            },
        }
    );

    const handleSubmit = (event) => {
        event.preventDefault();
        if (mutationConfigCommandError === true || mutationConfigCommandLoading === true) {
            return false
        }
        if (realizationDateValue === '' || typeof realizationDateValue !== 'object') {
            setRealizationDateErrorState("has-danger");
            return false
        }
        if (realizationDateErrorState !== '') {
            return false
        }
        mutationConfigCommand({
            realizationDate: realizationDateValue,
            serial: serial,
            commandName: commands.rebootDevice,
            commandValue: '1'
        })
    }

    // const handleRealizationDateValue = (date) => {
    //     if (typeof date !== 'object') {
    //         setRealizationDateErrorState('has-danger');
    //         setRealizationDateValue(date);
    //     }
    //     else {
    //         setRealizationDateErrorState('');
    //         setRealizationDateValue(date._d);
    //     }
    // }

    const [timeoutClearDataPost] = useTimeout(() => {
        mutationConfigCommandReset();
    }, 3000);


    return (
        <>
            <Col lg="6" md="6" sm="12">
                <Card className="card-stats">
                    <CardBody>
                        <Row>
                            <Col xs="5">
                                <div className="info-icon text-center icon-danger">
                                    <i className="tim-icons icon-button-power" />
                                </div>
                            </Col>
                            <Col xs="7">
                                <div className="numbers">
                                    <p className="card-category">Command</p>
                                    <CardTitle tag="h4">
                                        Reboot device
                                    </CardTitle>
                                </div>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            {/* <Col md='12' lg='4'>
                                <label>Realization date</label>
                                <FormGroup className={realizationDateErrorState}>
                                    <ReactDatetime
                                        inputProps={{
                                            className: "form-control",
                                            placeholder: "Datetime Picker Here",
                                        }}
                                        timeFormat={"HH:mm"}
                                        dateFormat={"YYYY-MM-DD"}
                                        value={realizationDateValue}
                                        onChange={handleRealizationDateValue}
                                    />
                                    {realizationDateErrorState === "has-danger" ? (
                                        <label className="error">
                                            Please enter a valid datetime YYYY-MM-DD HH:mm.
                                        </label>
                                    ) : null}
                                </FormGroup>
                            </Col> */}
                            <Col md='12' lg='12' className="date-submit-center">
                                <div className='submit-button-row'>
                                    <Button className="btn-fill" color={mutationConfigCommandError ? "danger" : mutationConfigCommandSuccess ? "success" : 'info'} type="submit"
                                        onClick={(event) => handleSubmit(event)}
                                    >
                                        {mutationConfigCommandLoading ? <SuspenseComponentText /> :
                                            mutationConfigCommandError ? (
                                                <div>
                                                    Failure <i className="tim-icons icon-simple-remove" />
                                                </div>
                                            ) : mutationConfigCommandSuccess ? (
                                                <div>
                                                    Success <i className="tim-icons icon-check-2" />
                                                </div>
                                            ) :
                                                `Submit`
                                        }
                                    </Button>
                                </div>
                            </Col>

                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </>
    )
}

export default DeviceConfigCommandReboot;