import React, { Suspense, useState } from 'react';
import SuspenseComponent from '../../../views/components/SuspenseComponent';
import DeviceBreadcrumbComponent from '../DeviceBreadcrumbComponent';
import moment from 'moment';
import DeviceInfoGoogleMap2 from './DeviceInfoGoogleMap2';
import DeviceInfoConstantData from './DeviceInfoConstantData';
import DeviceInfoVariableData from './DeviceInfoVariableData';
import { useParams } from "react-router-dom";
import { Card, CardBody, CardTitle, Row, Col, CardHeader } from "reactstrap";
import { getDeviceInfo, postDeviceInfo } from "API";
import { useQuery, useMutation, useQueryClient } from 'react-query';
import useTimeout from "hooks/useTimeout";
import useSerialCheck from 'hooks/useSerialCheck';
import { useDataDeviceAccess } from 'contexts/DataDeviceContext';
const DeviceInfo = (props) => {
    let { serial } = useParams();
    const userDataDeviceAccess = useDataDeviceAccess();
    const serialFound = useSerialCheck(userDataDeviceAccess, serial);
    const [textAreaValue, setTextAreaValue] = useState('');
    const [deviceNameValue, setDeviceNameValue] = useState('');
    const [latitudeValue, setLatitudeValue] = useState('');
    const [longitudeValue, setLongitudeValue] = useState('');
    const [simCardValue, setSimCardValue] = useState('');
    const [rangeLatitudeState, setRangeLatitudeState] = useState('');
    const [rangeLongitudeState, setRangeLongitudeState] = useState('');
    const [errorMachineState, setErrorMachineState] = useState('');
    const [errorCountryState, setErrorCountryState] = useState('');
    const [selectedCountryValue, setSelectedCountryValue] = useState('');
    const [selectedStateValue, setSelectedStateValue] = useState('');

    const [deviceLastConnection, setDeviceLastConnection] = useState('');
    const [deviceSerial, setDeviceSerial] = useState('');
    const [deviceSoftwareVersion, setDeviceSoftwareVersion] = useState('');
    const [deviceSubscriptionVersion, setDeviceSubscriptionVersion] = useState('');
    const [deviceDailyCost, setDeviceDailyCost] = useState('');
    const [deviceCurrency, setDeviceCurrency] = useState('');

    const [requiredNameState, setrequiredNameState] = useState("");
    const [requiredName, setrequiredName] = useState("");

    const queryClient = useQueryClient()
    const { mutate: mutationInfo, isLoading: mutationInfoLoading, isError: mutationInfoError, isSuccess: mutationInfoSuccess, reset: mutationInfoReset } = useMutation(
        {
            mutationFn: postDeviceInfo,
            onSuccess: () => {
                // Invalidate and refetch
                timeoutClearDataPost();
                queryClient.invalidateQueries({ queryKey: ['Info'] })
            },
            onError: () => {
                // Invalidate and refetch
                timeoutClearDataPost();
            },
        }
    );

    const [timeoutClearDataPost] = useTimeout(() => {
        mutationInfoReset();
    }, 3000);

    const handleNull = (data) => {
        if (data === null) {
            return ''
        }
        else if (data === 'Invalid date') {
            return ''
        }
        else {
            return data
        }
    }

    const dataInfo = useQuery('Info', () => getDeviceInfo(serial), {
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            setTextAreaValue(handleNull(data.data.notes));
            setDeviceNameValue(data.data.name);
            setLatitudeValue(handleNull(data.data.latitude));
            setLongitudeValue(handleNull(data.data.longitude));
            setSimCardValue(handleNull(data.data.sim_card_number));
            setSelectedCountryValue(handleNull(data.data.country));
            setSelectedStateValue(handleNull(data.data.working_state));

            setDeviceLastConnection(handleNull(moment.utc(data.data.last_connection).local().format("YYYY-MM-DD HH:mm:ss")));
            setDeviceSerial(handleNull(data.data.serial));
            setDeviceSoftwareVersion(handleNull(data.data.software_version));
            setDeviceSubscriptionVersion(handleNull(data.data.subscription_version));
            setDeviceDailyCost(handleNull(data.data.daily_cost));
            setDeviceCurrency(handleNull(data.data.currency));


        }
    });

    const handleSelectedCountryValue = (event) => {
        if (event.target.value === '') {
            setErrorCountryState('has-danger');
        }
        else {
            setErrorCountryState('');
        }
        setSelectedCountryValue(event.target.value);
    }

    const handleSelectedStateValue = (event) => {
        if (event.target.value === '') {
            setErrorMachineState('has-danger');
        }
        else {
            setErrorMachineState('');
        }
        setSelectedStateValue(event.target.value);
    }

    const handleTextAreaValue = (event) => {
        setTextAreaValue(event.target.value);
    }

    const handleLatitudeValue = (event) => {
        if (Number(event.target.value) > 90 || Number(event.target.value) < -90) {
            setRangeLatitudeState('has-danger');
        }
        else {
            setRangeLatitudeState('');
        }
        setLatitudeValue(event.target.value);
    }

    const handleLongitudeValue = (event) => {
        if (Number(event.target.value) > 180 || Number(event.target.value) < -180) {
            setRangeLongitudeState('has-danger');
        }
        else {
            setRangeLongitudeState('');
        }
        setLongitudeValue(event.target.value);
    }

    const handleSimCardValue = (event) => {
        setSimCardValue(event.target.value);
    }

    const clearForm = () => {
        setDeviceNameValue("");
        stateFunctions["setrequiredNameState"]("");
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        if (deviceNameValue === "") {
            setrequiredNameState("has-danger");
            return false;
        }


        if (mutationInfoError === true || mutationInfoLoading === true) {
            return false
        }

        if (selectedStateValue === "") {
            setErrorMachineState("has-danger");
        }

        if (selectedCountryValue === "") {
            setErrorCountryState("has-danger");
        }

        if (rangeLatitudeState !== '' || rangeLongitudeState !== '' || errorMachineState !== '' || errorCountryState !== '') {
            return false
        }

        mutationInfo({
            serial: serial,
            textAreaValue: textAreaValue,
            deviceNameValue: deviceNameValue,
            latitudeValue: latitudeValue,
            longitudeValue: longitudeValue,
            simCardValue: simCardValue,
            selectedCountryValue: selectedCountryValue,
            selectedStateValue: selectedStateValue,

        })
        clearForm();
    }

    const stateFunctions = {
        // register form
        setrequiredName: (value) => setrequiredName(value),
        setrequiredNameState: (value) => setrequiredNameState(value),
    };

    const verifyLength = (value, length) => {
        if (value.length >= length) {
            return true;
        }
        return false;
    };

    const change = (event, stateName, type, stateNameEqualTo, maxValue) => {

        switch (type) {
            case "lengthName":
                setDeviceNameValue(event.target.value)
                if (verifyLength(event.target.value, stateNameEqualTo)) {
                    stateFunctions["set" + stateName + "State"]("has-success");
                } else {
                    stateFunctions["set" + stateName + "State"]("has-danger");
                }
                break;
            default:
                break;
        }
        stateFunctions["set" + stateName](event.target.value);
    };

    return (
        <>
            <div className="content">
                <Row>
                    <Col lg="3">
                        <h4>
                            {serialFound !== true ? null : `${serial} Info`}
                        </h4>
                    </Col>
                    <Col lg="9">
                        <DeviceBreadcrumbComponent activePath={'DeviceInfo'} serial={serial} />
                    </Col>
                </Row>
                <Suspense fallback={<SuspenseComponent />}>
                    <Row>
                        <Col md="6">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Map Info</CardTitle>
                                </CardHeader>
                                <CardBody className='google-maps-wrapper'>
                                    <DeviceInfoGoogleMap2 latitudeValue={latitudeValue} longitudeValue={longitudeValue} deviceSerial={deviceSerial} />

                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="6">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Info</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <DeviceInfoConstantData
                                        deviceLastConnection={deviceLastConnection}
                                        deviceSerial={deviceSerial}
                                        deviceSoftwareVersion={deviceSoftwareVersion}
                                        deviceSubscriptionVersion={deviceSubscriptionVersion}
                                        deviceDailyCost={deviceDailyCost}
                                        deviceCurrency={deviceCurrency}
                                    />
                                </CardBody>
                            </Card>
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Settings</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <DeviceInfoVariableData
                                        textAreaValue={textAreaValue}
                                        handleTextAreaValue={handleTextAreaValue}
                                        deviceNameValue={deviceNameValue}
                                        latitudeValue={latitudeValue}
                                        handleLatitudeValue={handleLatitudeValue}
                                        longitudeValue={longitudeValue}
                                        handleLongitudeValue={handleLongitudeValue}
                                        handleSubmit={handleSubmit}
                                        simCardValue={simCardValue}
                                        handleSimCardValue={handleSimCardValue}
                                        rangeLatitudeState={rangeLatitudeState}
                                        rangeLongitudeState={rangeLongitudeState}
                                        selectedCountryValue={selectedCountryValue}
                                        selectedStateValue={selectedStateValue}
                                        handleSelectedCountryValue={handleSelectedCountryValue}
                                        handleSelectedStateValue={handleSelectedStateValue}
                                        errorMachineState={errorMachineState}
                                        errorCountryState={errorCountryState}
                                        mutationInfoError={mutationInfoError}
                                        mutationInfoSuccess={mutationInfoSuccess}
                                        mutationInfoLoading={mutationInfoLoading}
                                        change={change}
                                        requiredNameState={requiredNameState}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Suspense>
            </div>
        </>
    );
};

export default DeviceInfo;
