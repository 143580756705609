import React, { useState, useEffect } from "react";
import {
    Card,
    CardBody,
    CardTitle,
    Row,
    Col,
    Button,
    FormGroup,
    Label,
    Input
} from "reactstrap";
import Select from "react-select";
import SuspenseComponentText from '../../../views/components/SuspenseComponentText';
import useTimeout from "hooks/useTimeout";
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { getDeviceConfigUIVersion, postDeviceConfigCommandMachineType } from "API";
import { commands } from "variables/commands";

// reactstrap components
const DeviceConfigCommandRemoteMachineType = ({ serial }) => {
    const [realizationDateErrorState, setRealizationDateErrorState] = useState('');
    const [selectedMode, setSelectedMode] = useState(null);
    const [realizationDateValue, setRealizationDateValue] = useState(new Date());
    const [singleSelect, setsingleSelect] = useState({
        value: "",
        label: "Select Machine Type",
        isDisabled: true,
        state: null
    });

    const [singleSelectOptions, setSingleSelectOptions] = useState([
        {
            value: "",
            label: "Select Machine Type",
            isDisabled: true,
            state: null
        },
        {
            value: 0,
            label: "Free",
            isDisabled: false,
            state: 'free'
        },
        {
            value: 1,
            label: "Paid",
            isDisabled: false,
            state: 'paid'
        },
        {
            value: 2,
            label: "Free + donation",
            isDisabled: false,
            state: 'paid'
        },
        {
            value: 3,
            label: "QR mode",
            isDisabled: false,
            state: 'qr'
        },
    ]);

    const dataConfigMachineType = useQuery('ConfigMachineType', () => getDeviceConfigUIVersion(serial), {
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            setSelectedMode(Number(data.data.value));

        }
    });
    const queryClient = useQueryClient()
    const { mutate: mutationConfigCommand, isLoading: mutationConfigCommandLoading, isError: mutationConfigCommandError, isSuccess: mutationConfigCommandSuccess, reset: mutationConfigCommandReset } = useMutation(
        {
            mutationFn: postDeviceConfigCommandMachineType,
            onSuccess: () => {
                // Invalidate and refetch
                timeoutClearDataPost();
                setsingleSelect({
                    value: "",
                    label: "Select Machine Type",
                    isDisabled: true,
                    state: null
                })
                queryClient.invalidateQueries({ queryKey: ['Commands'] })
                queryClient.invalidateQueries({ queryKey: ['ConfigMachineType'] })

            },
            onError: () => {
                setsingleSelect({
                    value: "",
                    label: "Select Machine Type",
                    isDisabled: true,
                    state: null
                })
                // Invalidate and refetch
                timeoutClearDataPost();
                queryClient.invalidateQueries({ queryKey: ['Commands'] })

            },
        }
    );

    const handleSubmit = (event) => {
        event.preventDefault();
        if (mutationConfigCommandError === true || mutationConfigCommandLoading === true) {
            return false
        }
        if (realizationDateValue === '' || typeof realizationDateValue !== 'object') {
            setRealizationDateErrorState("has-danger");
            return false
        }
        if (realizationDateErrorState !== '') {
            return false
        }
        if (singleSelect.value === '') {
            return false
        }
        mutationConfigCommand({
            serial: serial,
            commandName: singleSelect.value
        })
    }

    const [timeoutClearDataPost] = useTimeout(() => {
        mutationConfigCommandReset();
    }, 3000);


    return (
        <>
            <Col lg="6" md="6">
                <Card className="card-stats">
                    <CardBody>
                        <Row>
                            <Col xs="5">
                                <div className="info-icon text-center icon-primary">
                                    <i className="tim-icons icon-sound-wave" />
                                </div>
                            </Col>
                            <Col xs="7">
                                <div className="numbers">
                                    <p className="card-category">Command</p>
                                    <CardTitle tag="h4">
                                        {
                                            `${selectedMode === null ? 'Change Machine Type State' : ''} 
                                            ${selectedMode === 0 ? 'Machine Type : Free' :
                                            selectedMode === 1 ? 'Machine Type : Paid' :
                                            selectedMode === 2 ? 'Machine Type : Free + donation' : 
                                            selectedMode === 3 ? 'Machine Type : QR mode' : ''}`
                                        }
                                    </CardTitle>
                                </div>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col md='12' lg='6'>
                                <label>Machine Type Select</label>

                                <Select
                                    className="react-select info"
                                    classNamePrefix="react-select"
                                    name="singleSelect"
                                    value={singleSelect}
                                    onChange={(value) => setsingleSelect(value)}
                                    options={singleSelectOptions}
                                    placeholder="Select Machine Type"
                                />

                            </Col>

                            <Col md='12' lg='6' className="date-submit-center">
                                <div className='submit-button-row'>
                                    <Button className="btn-fill" disabled={singleSelect.value === '' ? true : false} color={mutationConfigCommandError ? "danger" : mutationConfigCommandSuccess ? "success" : 'info'} type="submit"
                                        onClick={(event) => handleSubmit(event)}
                                    >
                                        {mutationConfigCommandLoading ? <SuspenseComponentText /> :
                                            mutationConfigCommandError ? (
                                                <div>
                                                    Failure <i className="tim-icons icon-simple-remove" />
                                                </div>
                                            ) : mutationConfigCommandSuccess ? (
                                                <div>
                                                    Success <i className="tim-icons icon-check-2" />
                                                </div>
                                            ) :
                                                `Submit`
                                        }
                                    </Button>
                                </div>
                            </Col>

                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </>
    )
}

export default DeviceConfigCommandRemoteMachineType;