import React, { useState } from 'react';
import Select from "react-select";
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    CardFooter,
    Button
} from "reactstrap";





const ConfigurationForm = ({ currentAddPassword, setCurrentAddPassword, currentAddName, setCurrentAddName,
    handleAdd, setAddRepeatedState, responseData, multipleAddSelect, setmultipleAddSelect, optionsGroup,
    addRepeatedState , convertObjectToArray }) => {
    const [requiredState, setrequiredState] = useState("");
    const [requiredPasswordState, setrequiredPasswordState] = useState("");
    const [required, setrequired] = useState("");
    const [requiredPassword, setrequiredPassword] = useState("");

    // function that verifies if a string has a given length or not
    const verifyLength = (value, length) => {
        if (value.length >= length) {
            return true;
        }
        return false;
    };

    const checkMatches = (itemGroupName) => {
        const matchFound = responseData.data.item_groups.some(
            (group) => group.name === itemGroupName
        );
        return matchFound
    };

    const change = (event, stateName, type, stateNameEqualTo, maxValue) => {
        const value = event.target.value;
        const newValue = value.replace(/\s/g, ''); // Remove spaces from the value

        switch (type) {
            case "length":
                setCurrentAddName(newValue)
                if (verifyLength(newValue, stateNameEqualTo)) {
                    if (checkMatches(newValue)) {
                        setAddRepeatedState(true);
                        stateFunctions["set" + stateName + "State"]("has-danger");
                    }
                    else {
                        setAddRepeatedState(false);
                        stateFunctions["set" + stateName + "State"]("has-success");
                    }
                } else {
                    setAddRepeatedState(false);
                    stateFunctions["set" + stateName + "State"]("has-danger");
                }
                break;
            case "lengthPassword":
                setCurrentAddPassword(newValue)
                if (verifyLength(newValue, stateNameEqualTo)) {
                    stateFunctions["set" + stateName + "State"]("has-success");
                } else {
                    stateFunctions["set" + stateName + "State"]("has-danger");
                }
                break;
            default:
                break;
        }
        stateFunctions["set" + stateName](newValue);
    };

    const stateFunctions = {
        // register form
        setrequired: (value) => setrequired(value),
        setrequiredPassword: (value) => setrequiredPassword(value),
        setrequiredState: (value) => setrequiredState(value),
        setrequiredPasswordState: (value) => setrequiredPasswordState(value),
    };

    const handleVerifyForm = (e) => {
        e.preventDefault();
        if (currentAddName === "") {
            setrequiredState("has-danger");
        }
        if (currentAddPassword === "") {
            setrequiredPasswordState("has-danger");
        }

        if (currentAddName === "" || currentAddPassword === "" || addRepeatedState === true) {
            return false;
        }
        const currentAddDeviceArr = convertObjectToArray(multipleAddSelect);
        handleAdd(currentAddName, currentAddPassword, currentAddDeviceArr)
        clearForm();
    }

    const clearForm = () => {
        setCurrentAddName("");
        setCurrentAddPassword("");
        setmultipleAddSelect(null);
        stateFunctions["setrequiredState"]("");
        stateFunctions["setrequiredPasswordState"]("");
    }

    return (

        <Row>
            <Col md="12">
                <Card>
                    <CardHeader>
                        <CardTitle tag="h4">Add New Service Account</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <Form action="/" className="form-horizontal" method="get">
                            <Row>
                                <Label sm="3">Username</Label>
                                <Col sm="9">
                                    <FormGroup className={requiredState}>
                                        <Input
                                            value={currentAddName}
                                            name="required"
                                            placeholder='Name'
                                            type="text"
                                            onChange={(e) => change(e, "required", "length", 1)}
                                        />
                                        {requiredState === "has-danger" && currentAddName === "" ? (
                                            <label className="error">
                                                This field is required.
                                            </label>
                                        ) : null}
                                        {requiredState === "has-danger" && addRepeatedState === true ? (
                                            <label className="error">
                                                Repeated field.
                                            </label>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Label sm="3">Password</Label>
                                <Col sm="9">
                                    <FormGroup className={requiredPasswordState}>
                                        <Input
                                            value={currentAddPassword}
                                            name="requiredPassword"
                                            placeholder='Password'
                                            type="text"
                                            onChange={(e) => change(e, "requiredPassword", "lengthPassword", 1)}
                                        />
                                        {requiredPasswordState === "has-danger" && currentAddPassword === "" ? (
                                            <label className="error">
                                                This field is required.
                                            </label>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Label sm="3">Access to devices</Label>
                                <Col sm="9">
                                    <Select
                                        className="react-select info"
                                        classNamePrefix="react-select"
                                        placeholder="Choose devices"
                                        name="multipleSelect"
                                        closeMenuOnSelect={false}
                                        isMulti
                                        value={multipleAddSelect}
                                        onChange={(value) => setmultipleAddSelect(value)}
                                        options={optionsGroup}
                                    />
                                </Col>
                            </Row>
                        </Form>
                    </CardBody>
                    <CardFooter className='submit-button-row'>
                        <Button className="btn-fill" color="primary" type="submit" onClick={handleVerifyForm}
                        >
                            Submit
                        </Button>
                    </CardFooter>
                </Card>
            </Col>
        </Row>

    )
}

export default ConfigurationForm;