import React, { Suspense, useState } from 'react';
import SuspenseComponent from '../../../views/components/SuspenseComponent';
import RatingsTable from './RatingsTable';
import RatingsSubmitDateRange from './RatingsSubmitDateRange';
import RatingsSubmitSerial from './RatingsSubmitSerial';
import RatingsSubmitDevices from './RatingsSubmitDevices';
import { getDeviceRatingsByRange , getDeviceRatings } from "API";
import { useMutation, useQueryClient, } from 'react-query'
import { Row, Col } from "reactstrap";
const Ratings = (props) => {
    const [valueDateRange, setValueDateRange] = useState([new Date(new Date().setDate(new Date().getDate() - 7)), new Date()]);
    const [serial, setSerial] = useState(null);
    const [dateStart, setDateStart] = useState(null);
    const [dateEnd, setDateEnd] = useState(null);
    const [forceReset, setForceReset] = useState(false);

    const [loading, setLoading] = useState(null);
    const queryClient = useQueryClient()
    const handleSubmit = () => {
        if (dateStart !== null && dateEnd !== null) {
            if (dateStart instanceof Date && dateEnd instanceof Date) {
                setLoading(true);
                mutation({ startRange: dateStart, endRange: dateEnd, serial : serial })
            }
        }
        else if(serial !== null) {
            setLoading(true);
            mutation({ startRange: dateStart, endRange: dateEnd, serial : serial })
        }
        else {
            setLoading(true);
            mutationPlain()
        }
    }

    const handleSetValueDateRange = (value) => {
        console.log(value)
        setDateStart(value[0])
        setDateEnd(value[1])
        setValueDateRange(value)
    }

    const handleResetDateRange = () => {
        setDateStart(null)
        setDateEnd(null)
        setValueDateRange([new Date(new Date().setDate(new Date().getDate() - 7)), new Date()])
        setForceReset(true)
        
        
    }

    React.useEffect(() => {
      if(forceReset){
        setForceReset(false)
        handleSubmit()
      }
    }, [forceReset])
    
    
    const handleResetSerial = () => {
        setSerial(null)
        setForceReset(true)
    }

    const { mutate: mutation } = useMutation(
        {
            mutationFn: getDeviceRatingsByRange,
            onSuccess: async (newData) => {

                setLoading(false);
                queryClient.setQueryData("RatingsData", newData);
            }
        }
    );

    const { mutate: mutationPlain } = useMutation(
        {
            mutationFn: getDeviceRatings,
            onSuccess: async (newData) => {

                setLoading(false);
                queryClient.setQueryData("RatingsData", newData);
            }
        }
    );

    return (
        <>
            <div className="content">
                <Row>
                    <Col lg="3">
                        <h4>
                            Return Person
                        </h4>
                    </Col>
                </Row>
                <Row>
                    <RatingsSubmitDateRange handleSetValueDateRange={handleSetValueDateRange} handleResetDateRange={handleResetDateRange} valueDateRange={valueDateRange} setValueDateRange={setValueDateRange} setDateStart={setDateStart} setDateEnd={setDateEnd} handleSubmit={handleSubmit} />
                    <RatingsSubmitSerial serial={serial} setSerial={setSerial} handleResetSerial={handleResetSerial} handleSubmit={handleSubmit} />
                    {/* <RatingsSubmitDevices handleSubmit={handleSubmit} /> */}
                </Row>

                <Suspense fallback={<SuspenseComponent />}>
                    <RatingsTable loading={loading} valueDateRange={valueDateRange} serial={serial} />
                </Suspense>
            </div>
        </>
    );
};

export default Ratings;
