import { useState, useEffect } from 'react';

export default function useSerialCheck(serials, serial) {
  const [found, setFound] = useState(null);

  useEffect(() => {
    if (serials !== null) {
        const serialExists = serials.some(s => s.serial === serial);
        setFound(serialExists);
    }
  }, [serials, serial]);

  return found;
}