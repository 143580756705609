import React from 'react';
import {
    Row,
    Col,
    FormGroup,
    Input
} from "reactstrap";
function DeviceInfoConstantData({
    deviceLastConnection,
    deviceSerial,
    deviceSoftwareVersion,
    deviceSubscriptionVersion,
    deviceDailyCost,
    deviceCurrency
}) {


    return (
        <>
            <Row>
                <Col md="12">
                    <label>Last server connection:</label>
                    <FormGroup>
                        <Input type="text" className='constant-form' defaultValue={deviceLastConnection} autoComplete="off" readOnly={true} />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md="6">
                    <label>Serial</label>
                    <FormGroup>
                        <Input type="text" className='constant-form' defaultValue={deviceSerial} autoComplete="off" readOnly={true} />
                    </FormGroup>
                </Col>
                <Col md="6">
                    <label>Software Version</label>
                    <FormGroup>
                        <Input type="text" className='constant-form' defaultValue={deviceSoftwareVersion} autoComplete="off" readOnly={true} />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md="6">
                    <label>Subscription Version</label>
                    <FormGroup>
                        <Input type="text" className='constant-form' defaultValue={deviceSubscriptionVersion} autoComplete="off" readOnly={true} />
                    </FormGroup>
                </Col>
                <Col md="6">
                    <label>Daily Cost {deviceCurrency === '' ? null : `[${deviceCurrency}]`}</label>
                    <FormGroup>
                        <Input type="text" className='constant-form' defaultValue={deviceDailyCost} autoComplete="off" readOnly={true} />
                    </FormGroup>
                </Col>
            </Row>
        </>
    );
}

export default DeviceInfoConstantData;
