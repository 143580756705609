import React, { useContext, useState , useEffect, createContext } from "react";

const DataDeviceAccessContext = createContext()
const IntervalDataDeviceAccessContext = createContext()
const DataDeviceAccessUpdateContext = createContext()
const IntervalDataDeviceAccessUpdateContext = createContext()

export function useDataDeviceAccess() {
    return useContext(DataDeviceAccessContext)
}
export function useDataDeviceAccessUpdate() {
    return useContext(DataDeviceAccessUpdateContext)
}

export function useIntervalDataDeviceAccess() {
    return useContext(IntervalDataDeviceAccessContext)
}
export function useIntervalDataDeviceAccessUpdate() {
    return useContext(IntervalDataDeviceAccessUpdateContext)
}
export function DataDeviceAccessProvider({ children }) {

    const [dataDeviceAccess, setDataDeviceAccess] = useState(null);
    const [intervalDataDeviceAccess, setIntervalDataDeviceAccess] = useState(null);

    function changeDataDeviceAccess(devicesAccess) {
        setDataDeviceAccess(devicesAccess)
    }

    function changeIntervalDataDeviceAccess(devicesAccess) {
        setIntervalDataDeviceAccess(devicesAccess)
    }    

    return (
        <DataDeviceAccessContext.Provider value={dataDeviceAccess}>
            <DataDeviceAccessUpdateContext.Provider value={changeDataDeviceAccess}>
                <IntervalDataDeviceAccessContext.Provider value={intervalDataDeviceAccess}>
                    <IntervalDataDeviceAccessUpdateContext.Provider value={changeIntervalDataDeviceAccess}>
                        {children}
                    </IntervalDataDeviceAccessUpdateContext.Provider>
                </IntervalDataDeviceAccessContext.Provider>
            </DataDeviceAccessUpdateContext.Provider>
        </DataDeviceAccessContext.Provider>
    )
}