import React, { useState } from "react";
import {
    Card,
    CardBody,
    CardTitle,
    Row,
    Col,
    CardFooter,
} from "reactstrap";
import moment from 'moment';
// reactstrap components
import { useQuery } from 'react-query';
import { getDeviceParameter } from "API";
import SuspenseComponentText from '../../../views/components/SuspenseComponentText';

const paramTitles = {
    technical_break: "Technical Break",
    buy_rent: "Buy&Rent",
    swap: "Swap",
    return: "Return",
    columns_max_capacity: "Columns Max Capacity",
    column1_quantity: "Column 1 Quantity",
    column2_quantity: "Column 2 Quantity",
    column3_quantity: "Column 3 Quantity",
    column4_quantity: "Column 4 Quantity",
    column1_status: "Column 1 Status",
    column2_status: "Column 2 Status",
    column3_status: "Column 3 Status",
    column4_status: "Column 4 Status",
    price: "Price",
    price_rent: "Price Rent",
    deposit_value: "Deposit Value",
    column_critical_value: "Column Critical Value",
    terminal_serial: "Terminal Serial",
    client: "Client",
    fontColor: "fontColor",
    fontSecondaryColor: "fontSecondaryColor",
    fontTertiaryColor: "fontTertiaryColor",
    backgroundColor: "backgroundColor",
    buttonBackgroundColor: "buttonBackgroundColor",
    buttonFontColor: "buttonFontColor",
    support_phone_number: "Support Phone Number",
}


const DeviceDetailsParameter = ({ serial, nameParam , elSize, elColor }) => {
    const [dataParameter, setDataParameter] = useState(null);
    const dataGetParameter = useQuery(nameParam, () => getDeviceParameter(serial, nameParam), {
        refetchInterval: 60000,
        onSuccess: (data) => {

            setDataParameter(data.data.value);
        }
    });

    const handleDataParameter = () => {
        if (nameParam === 'technical_break') {
            return dataParameter === '0' ? 'Off' : 'On'
        }
        else if (nameParam === 'price' || nameParam === 'price_rent' || nameParam === 'deposit_value') {
            return `${dataParameter} £`
        }
        else if (nameParam === 'column1_status' || nameParam === 'column2_status' || nameParam === 'column3_status' || nameParam === 'column4_status') {
            return dataParameter === '0' ? 'Inactive' : 'Active'
        }
        else {
            return dataParameter
        }
    }


    return (
        <>
            <Col lg={elSize} md={elSize}>
                <Card className="card-stats">
                    <CardBody>
                        <Row>
                            <Col xs="3">
                                <div className={`info-icon text-center icon-${elColor}`}>
                                    <i className="tim-icons icon-components" />
                                </div>
                            </Col>
                            <Col xs="9">
                                <div className="numbers">
                                    <p className="card-category">{paramTitles[nameParam]}</p>
                                    <CardTitle tag="h3">
                                        {dataGetParameter.isLoading ? <SuspenseComponentText /> :
                                            dataGetParameter.isError ? `Error` : handleDataParameter()
                                        }
                                    </CardTitle>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter>
                        {/* <hr />
                        <div className="stats">
                            <i className="tim-icons icon-alert-circle-exc" />
                            {dataGetParameter.isLoading ? <SuspenseComponentText /> :
                                dataGetParameter.isError ? `Error` : null
                            }
                        </div> */}
                    </CardFooter>
                </Card>
            </Col>
        </>
    )
}

export default DeviceDetailsParameter;