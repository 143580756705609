import React, { useState } from "react";
import {
    Card,
    CardBody,
    CardTitle,
    Row,
    Col,
    CardFooter,
} from "reactstrap";
import moment from 'moment';
// reactstrap components
import { useQuery } from 'react-query';
import { getDeviceStatus } from "API";
import SuspenseComponentText from '../../../views/components/SuspenseComponentText';
const DeviceDetailsStatus = ({ serial }) => {
    const [dataStatus, setDataStatus] = useState(null);
    const [dataDateStatus, setDataDateStatus] = useState(null);
    const dataGetStatus = useQuery('Status', () => getDeviceStatus(serial), {
        refetchInterval: 60000,
        onSuccess: (data) => {
            setDataStatus(data.data.status);
            setDataDateStatus(moment.utc(data.data.timestamp).local().format("YYYY-MM-DD HH:mm:ss"));
        }
    });

    return (
        <>
            <Col lg="6" md="6">
                <Card className="card-stats">
                    <CardBody>
                        <Row>
                            <Col xs="5">
                                <div className="info-icon text-center icon-success">
                                    <i className="tim-icons icon-alert-circle-exc" />
                                </div>
                            </Col>
                            <Col xs="7">
                                <div className="numbers">
                                    <p className="card-category">Status</p>
                                    <CardTitle tag="h3">
                                        {dataGetStatus.isLoading ? <SuspenseComponentText /> :
                                             dataGetStatus.isError ? `Error` : dataStatus
                                        }
                                    </CardTitle>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter>
                        <hr />
                        <div className="stats">
                            <i className="tim-icons icon-alert-circle-exc" />
                            {dataGetStatus.isLoading ? <SuspenseComponentText /> :
                               dataGetStatus.isError ? `Error` : dataDateStatus === null ? '-' : dataDateStatus
                            }
                        </div>
                    </CardFooter>
                </Card>
            </Col>
        </>
    )
}

export default DeviceDetailsStatus;