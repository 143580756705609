import React, { useState } from 'react';
import { getDeviceReturnMachine, getDeviceReturnMachineTEST } from "API";
import { useQuery } from 'react-query';
import SuspenseComponent from '../../../views/components/SuspenseComponent';
import ReactTable from "components/ReactTable/ReactTableConfig.js";
import moment from 'moment';

import {
    Card,
    CardBody,
    CardTitle,
    Row,
    Col,
    CardHeader,
} from "reactstrap";
const ReturnMachineTable = ({ valueDateRange, loading }) => {
    const [data, setData] = useState([]);
    const dataReturnMachine = useQuery('ReturnMachineData', () => getDeviceReturnMachine( valueDateRange[0], valueDateRange[1]), {
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            setData(
                data.data.contact_info_list?.map((prop, key) => {
                    return {
                        id: prop.id,
                        email: prop.email,
                        phone_number: prop.phone_number,
                        returned: prop.returned === true ? "Yes" : "No",
                        session_ID: prop.session_ID,
                        date: moment.utc(prop.date).local().format("YYYY-MM-DD HH:mm:ss"),
                        actions: (
                            // we've added some custom button actions
                            <div className="actions-right">

                            </div>
                        )
                    };
                })
            );
        }
    });

    const dataReturnMachineTEST = useQuery('ReturnMachineDataTEST', () => getDeviceReturnMachineTEST(valueDateRange[0], valueDateRange[1]), {
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
        }
    });

    const getTrProps = (rowInfo) => {
        return {
            style: {
                background: 'green',
                color: 'white'
            }
        }
    }

    return (
        <Row>
            <Col xs={12} md={12}>
                <Card>
                    <CardHeader>
                        <CardTitle tag="h4">Return Machine Data</CardTitle>
                    </CardHeader>
                    <CardBody>

                        {
                            loading || dataReturnMachine.isLoading || dataReturnMachine.isFetching ? <SuspenseComponent /> :
                                <div className='return-machine-table'>

                                    <ReactTable
                                        data={data}
                                        filterable
                                        resizable={false}
                                        columns={[
                                            {
                                                Header: "Date",
                                                accessor: "date",
                                                sortable: true,
                                                sortMethod: (a, b) => {
                                                    const dateA = new Date(a);
                                                    const dateB = new Date(b);
                                                    if (dateA > dateB) return -1;
                                                    if (dateA < dateB) return 1;
                                                    return 0;
                                                  },
                                            },
                                            {
                                                Header: "Email",
                                                accessor: "email"
                                            },{
                                                Header: "Phone Number",
                                                accessor: "phone_number"
                                            },{
                                                Header: "Returned",
                                                accessor: "returned"
                                            },{
                                                Header: "Session ID",
                                                accessor: "session_ID"
                                            },
                                            {
                                                Header: "",
                                                accessor: "actions",
                                                sortable: false,
                                                filterable: false
                                            }

                                        ]}
                                        defaultPheartbeatSize={10}
                                        showPaginationTop
                                        showPaginationBottom={false}
                                        getTrProps={getTrProps}
                                        className="-striped -highlight"
                                    />
                                </div>
                        }


                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default ReturnMachineTable;