/*!

=========================================================
* Black Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { getLastWeekLogs } from "../../API"
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
  InputGroup,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container,
  Modal,
  UncontrolledTooltip
} from "reactstrap";
import { useQuery } from 'react-query';
import moment from 'moment';

const UserNavbar = (props) => {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [modalSearch, setModalSearch] = React.useState(false);
  const [isBellDropdown, setIsBellDropdown] = React.useState(true);
  const [paddingLeft, setPaddingLeft] = React.useState('nav_bell__pd_left');
  const [isDropdownMinWidth, setIsDropdownMinWidth] = React.useState('dropdown-bell_min_width');
  const [countLogs, setCountLogs] = React.useState("");
  const [color, setColor] = React.useState("navbar-transparent");
  const history = useHistory();
  
  React.useEffect(() => {
    window.addEventListener("resize", updateColor);
    return function cleanup() {
      window.removeEventListener("resize", updateColor);
    };
  });

  const dataLastWeekLogs = useQuery('LastWeekLogs', () => getLastWeekLogs(), {
    refetchOnWindowFocus: false,
    refetchInterval: 60000,
    onSuccess: (data) => {
      setCountLogs(data.data.last_week_logs.logs_100.length + data.data.last_week_logs.logs_200.length + data.data.last_week_logs.logs_300.length)
    }
  });

  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993) {
      setIsBellDropdown(false);
    } else {
      setIsBellDropdown(true);
    }
    if (window.innerWidth < 993 && collapseOpen) {
      setColor("bg-white");
    } else {
      setColor("navbar-transparent");
    }
    if (window.innerWidth < 993 && collapseOpen) {
      setPaddingLeft('nav_bell__pd_left');
      setIsDropdownMinWidth('dropdown-bell_min_width');
    } else {
      setPaddingLeft("");
      setIsDropdownMinWidth("");
    }
  };
  // this function opens and closes the collapse on small devices
  const toggleCollapse = () => {
    if (collapseOpen) {
      setColor("navbar-transparent");

    } else {
      setColor("bg-white");

    }
    setCollapseOpen(!collapseOpen);
  };
  // this function is to open the Search modal
  const toggleModalSearch = () => {
    setModalSearch(!modalSearch);
  };
  const handleLogout = (e) => {
    const fakeAuth = sessionStorage.getItem("token");
    const fakeGuid = sessionStorage.getItem("guid");
    if (fakeAuth) {
      sessionStorage.removeItem("token")
    }
    if (fakeGuid) {
      sessionStorage.removeItem("guid")
    }
    history.push('/auth/login');
  };

  const handleClick = (serial , timestamp) => {
    history.push({
      pathname: `/user/device/logs/${serial}`,
      state: { timestamp: timestamp },
    });
  }

  return (
    <>
      <Navbar
        className={classNames("navbar-absolute", {
          [color]: props.location.pathname.indexOf("full-screen-map") === -1
        })}
        expand="lg"
      >
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-minimize d-inline">
              <Button
                className="minimize-sidebar btn-just-icon"
                color="link"
                id="tooltip209599"
                onClick={props.handleMiniClick}
              >
                <i className="tim-icons icon-align-center visible-on-sidebar-regular" />
                <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini" />
              </Button>
              <UncontrolledTooltip
                delay={0}
                target="tooltip209599"
                placement="right"
              >
                Sidebar toggle
              </UncontrolledTooltip>
            </div>
            <div
              className={classNames("navbar-toggle d-inline", {
                toggled: props.sidebarOpened
              })}
            >
              <button
                className="navbar-toggler"
                type="button"
                onClick={props.toggleSidebar}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <NavbarBrand href="#pablo" onClick={(e) => e.preventDefault()}>
              {props.brandText}
            </NavbarBrand>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navigation"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={toggleCollapse}
          >
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </button>
          <Collapse navbar isOpen={collapseOpen}>
            <Nav className="ml-auto" navbar>
              {/* <InputGroup className="search-bar" tag="li">
                <Button
                  color="link"
                  data-target="#searchModal"
                  data-toggle="modal"
                  id="search-button"
                  onClick={toggleModalSearch}
                >
                  <i className="tim-icons icon-zoom-split" />
                  <span className="d-lg-none d-md-block">Search</span>
                </Button>
              </InputGroup> */}
              <UncontrolledDropdown className={isBellDropdown ? "bell-dropdown" : ""} nav>
                <DropdownToggle
                  caret
                  color="default"
                  data-toggle="dropdown"
                  nav
                >
                  {isBellDropdown ? <div className="notification custom-bell-notification d-none d-lg-block d-xl-block">{countLogs}</div> : ""}
                  {/* <div className="notification d-none d-lg-block d-xl-block" /> */}
                  <i className={`tim-icons icon-bell-55 ${countLogs !== "" && countLogs !== 0 ? "animated-bell" : ""}`} />
                  <p className="d-lg-none">Notifications</p>
                </DropdownToggle>
                <DropdownMenu className={`dropdown-navbar dropdown-bell ${isDropdownMinWidth}`} right tag="ul">
                  {dataLastWeekLogs.isSuccess === true ? <>
                    {dataLastWeekLogs.data?.data.last_week_logs.logs_300.map((log, index) => (
                      <NavLink tag="li" key={index} onClick={() => handleClick(log.serial , log.timestamp)}>
                        <DropdownItem className={`nav-item ${paddingLeft}`}>
                          <div className="bell-nav-item-div-flex">
                            <div className="bell-logs-photo" style={{ backgroundColor: '#26293d' }}>
                              <i className="tim-icons bell-logs-photo-tim-icons icon-alert-circle-exc" />
                            </div>
                            <div>
                              <div className="bell-wrap-text">
                                <span className="bell-serial-name">{log.serial}</span> - {log.log_msg}
                              </div>
                              <div>
                                <span>{moment(log.timestamp).fromNow()}</span>
                              </div>
                            </div>
                          </div>
                        </DropdownItem>
                      </NavLink>
                    ))}
                    {dataLastWeekLogs.data?.data.last_week_logs.logs_200.map((log, index) => (
                      <NavLink tag="li" key={index} onClick={() => handleClick(log.serial , log.timestamp)}>
                        <DropdownItem className={`nav-item ${paddingLeft}`}>
                          <div className="bell-nav-item-div-flex">
                            <div className="bell-logs-photo" style={{ backgroundColor: '#e14eca' }}>
                              <i className="tim-icons bell-logs-photo-tim-icons icon-support-17" />
                            </div>
                            <div>
                              <div className="bell-wrap-text">
                                <span className="bell-serial-name">{log.serial}</span> - {log.log_msg}
                              </div>
                              <div>
                                {/* <span>{moment.utc(log.timestamp).local().format("YYYY-MM-DD HH:mm:ss")}</span> */}
                                <span>{moment(log.timestamp).fromNow()}</span>
                              </div>
                            </div>
                          </div>
                        </DropdownItem>
                      </NavLink>
                    ))}
                    {dataLastWeekLogs.data?.data.last_week_logs.logs_100.map((log, index) => (
                      <NavLink tag="li" key={index} onClick={() => handleClick(log.serial , log.timestamp)}>
                        <DropdownItem className={`nav-item ${paddingLeft}`}>
                          <div className="bell-nav-item-div-flex">
                            <div className="bell-logs-photo" style={{ backgroundColor: '#1d8cf8' }}>
                              <i className="tim-icons bell-logs-photo-tim-icons icon-notes" />
                            </div>
                            <div>
                              <div className="bell-wrap-text">
                                <span className="bell-serial-name">{log.serial}</span> - {log.log_msg}
                              </div>
                              <div>
                                <span>{moment(log.timestamp).fromNow()}</span>
                              </div>
                            </div>
                          </div>
                        </DropdownItem>
                      </NavLink>
                    ))}
                  </> :
                    <NavLink tag="li">
                      <DropdownItem className="nav-item">

                      </DropdownItem>
                    </NavLink>}
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  data-toggle="dropdown"
                  nav
                  onClick={(e) => e.preventDefault()}

                >
                  <div className="photo">
                    <img alt="..." src={require("assets/img/avatar-none.jpg")} />
                  </div>
                  <b className="caret d-none d-lg-block d-xl-block" />
                  <p className="d-lg-none">Log out</p>
                </DropdownToggle>
                <DropdownMenu className="dropdown-navbar" right tag="ul">
                  <NavLink tag="li">
                    <DropdownItem className="nav-item">Profile</DropdownItem>
                  </NavLink>
                  <NavLink tag="li">
                    <DropdownItem className="nav-item">Settings</DropdownItem>
                  </NavLink>
                  <DropdownItem divider tag="li" />
                  <NavLink to="/auth/login" tag="li">
                    <DropdownItem className="nav-item" onClick={(e) => handleLogout(e)}>Log out</DropdownItem>
                  </NavLink>
                </DropdownMenu>
              </UncontrolledDropdown>
              <li className="separator d-lg-none" />
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
      <Modal
        modalClassName="modal-search"
        isOpen={modalSearch}
        toggle={toggleModalSearch}
      >
        <div className="modal-header">
          <Input id="inlineFormInputGroup" placeholder="SEARCH" type="text" />
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleModalSearch}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
        </div>
      </Modal>
    </>
  );
};

export default UserNavbar;
