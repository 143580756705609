import React, { useState, useEffect } from 'react';
import Select from "react-select";
import {
    Card,
    CardBody,
    CardTitle,
    Col,
    CardHeader,
    FormGroup,
    Button
} from "reactstrap";
import { useDataDeviceAccess } from 'contexts/DataDeviceContext';


const ReturnPersonSubmitDevices = ({ handleSubmit }) => {
    const [multipleSelect, setMultipleSelect] = useState(null);
    const [multipleOptionsSelect, setMultipleOptionsSelect] = useState([
        {
            value: "",
            label: " Multiple Options",
            isDisabled: true
        },]);
    const userDataDeviceAccess = useDataDeviceAccess();

    const handleAddObjects = () => {
        const newObjects = userDataDeviceAccess.map(option => ({ value: option, label: option }));
        setMultipleOptionsSelect(prevState => [...prevState, ...newObjects]);
    };
    useEffect(() => {
        // Adding objects to multipleOptionsSelect
        if (userDataDeviceAccess) {
            handleAddObjects();

        }
    }, [userDataDeviceAccess])




    return (

        <Col lg='4' md="6" sm="12" >
            <Card>
                <CardHeader>
                    <CardTitle tag="h4">Devices</CardTitle>
                </CardHeader>
                <CardBody>
                    <FormGroup>
                        <Select
                            className="react-select info"
                            classNamePrefix="react-select"
                            placeholder="Choose Devices"
                            name="multipleSelect"
                            closeMenuOnSelect={false}
                            isMulti
                            value={multipleSelect}
                            onChange={(value) => setMultipleSelect(value)}
                            options={multipleOptionsSelect}
                        />
                    </FormGroup>
                    <div className='submit-button-row'>
                        <Button className="btn-fill" color="primary" type="submit" onClick={() => handleSubmit()}>
                            Submit
                        </Button>
                    </div>
                </CardBody>
            </Card>
        </Col>

    )
}

export default ReturnPersonSubmitDevices;