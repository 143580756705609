import React from 'react';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import {
    Card,
    CardBody,
    CardTitle,
    Row,
    Col,
    CardHeader,
    FormGroup,
    Button
} from "reactstrap";

const DeviceLogsSubmitDateRange = ({ valueDateRange, setValueDateRange, handleSubmit }) => {

    return (
        <Card className='d-flex align-items-center flex-1'>
            <CardHeader className='mt-4'>
                <CardTitle tag="h4">Logs Date Range</CardTitle>
            </CardHeader>
            <CardBody>
                <FormGroup className='mt-3'>
                    <DateRangePicker
                        rangeDivider={` : `}
                        className={`form-logs-input-date`}
                        value={valueDateRange}
                        onChange={setValueDateRange}
                        format={`y-MM-dd`} />
                </FormGroup>
                <div className='submit-button-row mt-3'>
                    <Button className="btn-fill" color="primary" type="submit" onClick={() => handleSubmit()}>
                        Submit
                    </Button>
                </div>
            </CardBody>
        </Card>
    )
}

export default DeviceLogsSubmitDateRange;