import React, { useState } from "react";
import {
    Card,
    CardBody,
    CardTitle,
    Row,
    Col,
    CardFooter,
} from "reactstrap";
import moment from 'moment';
// reactstrap components
import { useQuery } from 'react-query';
import { getDeviceParameter } from "API";
import SuspenseComponentText from '../../../views/components/SuspenseComponentText';

const paramTitles = {
    technical_break: "Technical Break",
    buy_rent: "Buy&Rent",
    swap: "Swap",
    return: "Return",
    columns_max_capacity: "Columns Max Capacity",
    column1_quantity: "Column 1 Quantity",
    column2_quantity: "Column 2 Quantity",
    column3_quantity: "Column 3 Quantity",
    column4_quantity: "Column 4 Quantity",
    column1_status: "Column 1 Status",
    column2_status: "Column 2 Status",
    column3_status: "Column 3 Status",
    column4_status: "Column 4 Status",
    column1: "Column 1 Status & Quantity",
    column2: "Column 2 Status & Quantity",
    column3: "Column 3 Status & Quantity",
    column4: "Column 4 Status & Quantity",
    price: "Price",
    price_rent: "Price Rent",
    deposit_value: "Deposit Value",
    column_critical_value: "Column Critical Value",
    terminal_serial: "Terminal Serial",
    client: "Client",
    fontColor: "fontColor",
    fontSecondaryColor: "fontSecondaryColor",
    fontTertiaryColor: "fontTertiaryColor",
    backgroundColor: "backgroundColor",
    buttonBackgroundColor: "buttonBackgroundColor",
    buttonFontColor: "buttonFontColor",
    support_phone_number: "Support Phone Number",
}


const DeviceDetailsParameterColumn = ({ serial, nameParam, elSize, elColor }) => {
    const [dataParameterStatus, setDataParameterStatus] = useState(null);
    const [dataParameterQuantity, setDataParameterQuantity] = useState(null);
    const [dataParameterCriticalValue, setDataParameterCriticalValue] = useState(null);
    const dataGetParameterStatus = useQuery(`${nameParam}_status`, () => getDeviceParameter(serial, `${nameParam}_status`), {
        refetchInterval: 60000,
        onSuccess: (data) => {
            setDataParameterStatus(data.data.value);
        }
    });
    const dataGetParameterQuantity = useQuery(`${nameParam}_quantity`, () => getDeviceParameter(serial, `${nameParam}_quantity`), {
        refetchInterval: 60000,
        onSuccess: (data) => {
            setDataParameterQuantity(data.data.value);
        }
    });
    const dataGetParameterCriticalValue = useQuery(`column_critical_value`, () => getDeviceParameter(serial, `column_critical_value`), {
        refetchInterval: 60000,
        onSuccess: (data) => {
            setDataParameterCriticalValue(data.data.value);
        }
    });

    const CriticalComponent = () => {
        return (
            <>
                {dataParameterQuantity === null || dataParameterCriticalValue === null ?
                    null : Number(dataParameterCriticalValue) >= Number(dataParameterQuantity) ?
                        <span className="label-has-danger label-ghost-effect"> Critical Value Reached</span> : null}
            </>


        )

    }

    const handleDataParameter = (parameterName, dataParameter) => {
        if (parameterName === 'technical_break') {
            return dataParameter === '0' ? 'Off' : 'On'
        }
        else if (parameterName === 'price' || parameterName === 'price_rent' || parameterName === 'deposit_value') {
            return `${dataParameter} £`
        }
        else if (parameterName === 'column1_status' || parameterName === 'column2_status' || parameterName === 'column3_status' || parameterName === 'column4_status') {
            return dataParameter === '0' ? <span className="label-has-danger">Inactive</span> : <span className="label-has-success">Active</span>
        }
        else if (parameterName === 'column1_quantity' || parameterName === 'column2_quantity' || parameterName === 'column3_quantity' || parameterName === 'column4_quantity') {
            return ` - ${dataParameter}`
        }
        else {
            return dataParameter
        }
    }


    return (
        <>
            <Col lg={elSize} md={elSize}>
                <Card className="card-stats">
                    <CardBody>
                        <Row>
                            <Col xs="3">
                                <div className={`info-icon text-center icon-${elColor}`}>
                                    <i className="tim-icons icon-components" />
                                </div>
                            </Col>
                            <Col xs="9">
                                <div className="numbers">
                                    <p className="card-category">{paramTitles[nameParam]}</p>
                                    <CardTitle tag="h3">
                                        {dataGetParameterStatus.isLoading || dataGetParameterQuantity.isLoading ? <SuspenseComponentText /> :
                                            dataGetParameterStatus.isError || dataGetParameterQuantity.isError ? `Error` :
                                                (
                                                    <>
                                                        {handleDataParameter(`${nameParam}_status`, dataParameterStatus)}
                                                        {handleDataParameter(`${nameParam}_quantity`, dataParameterQuantity)}
                                                    </>
                                                )
                                        }

                                    </CardTitle>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter>
                        <hr />
                        <div className="stats">
                            <i className="tim-icons icon-alert-circle-exc" />
                            {dataGetParameterStatus.isLoading ? <SuspenseComponentText /> :
                                dataGetParameterStatus.isError ? `Error` : <CriticalComponent />
                            }
                        </div>
                    </CardFooter>
                </Card>
            </Col>
        </>
    )
}

export default DeviceDetailsParameterColumn;