const commands = {
    rebootDevice : 'reboot_device',
    resetCounter : 'reset_counter',
    openLocker : 'open_locker',
    technicalBreak : 'technical_break',
    buyRent : 'buy_rent',
    swap : 'swap',
    swapPaid : 'swap_paid',
    return : 'return',
    columnStatus : 'column_status',
    machineTypeStatus : 'machine_type_status',
    price : 'price',
    priceSwap : 'price_swap',
    currency: 'currency',
    deposit_value_get : 'deposit_value',
    deposit_value_post : 'deposit',
    support_phone_number : 'support_phone_number',
    charity_organization : 'charity_organization',
    active_languages : 'active_languages',
    o2_config : 'o2_config',
};

export { commands };