import React from 'react';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import {
    Card,
    CardBody,
    CardTitle,
    Col,
    CardHeader,
    FormGroup,
    Button
} from "reactstrap";
import { getAllDevices } from "API";
import { useQuery } from 'react-query';
import Select from "react-select";

const RatingsSubmitSerial = ({ serial, setSerial, handleSubmit, handleResetSerial }) => {

    const [data, setData] = React.useState([]);
    const dataDevices = useQuery('Dashboard', () => getAllDevices(), {
        onSuccess: (data) => {
            console.log('data')
            console.log(data)
            console.log('data')
            setData(
                data.data?.map((prop, key) => {
                    ;
                    return {
                        id: key,
                        dashboardName: prop.name,
                        dashboardSerial: prop.serial,
                    };
                })
            );
        },
        refetchInterval: 30000,
    });

    return (

        <Col lg='6' md="6" sm="12" >
            <Card>
                <CardHeader>
                    <CardTitle tag="h4">Ratings Serial</CardTitle>
                </CardHeader>
                <CardBody>
                    <FormGroup>
                        {/* <DateRangePicker
                            rangeDivider={` : `}
                            className={`form-logs-input-date`}
                            value={serial}
                            onChange={setSerial}
                            format={`y-MM-dd`} /> */}
                        <div className="-center flex-nowrap">
                            <Select
                                className="react-select info w-100"
                                classNamePrefix="react-select"
                                name="singleSelect"
                                value={serial}
                                onChange={setSerial}
                                options={data.map((prop, key) => {
                                    return {
                                        value: prop.dashboardSerial,
                                        label: `${prop.dashboardName} - ${prop.dashboardSerial}`,
                                    };
                                })}
                                placeholder="Select device serial"
                            />

                        </div>
                    </FormGroup>
                    <div className='submit-button-row'>
                        <Button className="btn-fill" color="primary" type="submit" onClick={() => handleResetSerial()}>
                            Reset Serial
                        </Button>
                        <Button className="btn-fill" color="primary" type="submit" onClick={() => handleSubmit()}>
                            Submit
                        </Button>
                    </div>
                </CardBody>
            </Card>
        </Col>

    )
}

export default RatingsSubmitSerial;