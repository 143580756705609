import React, { Suspense, useState, useEffect } from 'react';
import SuspenseComponent from '../../../views/components/SuspenseComponent';
import ItemDetailsTable from './ItemDetailsTable';
import { useParams } from "react-router-dom";
import ItemDetailsBreadcrumbComponent from './ItemDetailsBreadcrumbComponent';

const ItemDetails = (props) => {
  let { serial, itemid, itemName } = useParams();
  const [loading, setLoading] = useState(null);


  return (
    <>
      <div className="content">
        <ItemDetailsBreadcrumbComponent activePath={'Items'} serial={serial} itemid={itemid} />
        <Suspense fallback={<SuspenseComponent />}>
          <ItemDetailsTable loading={loading} serial={serial} itemid={itemid} itemName={itemName} />
        </Suspense>
      </div>
    </>
  );
};

export default ItemDetails;
