import React, { useState } from "react";
import {
    Card,
    CardBody,
    CardTitle,
    Row,
    Col,
    Button,
    Spinner,
} from "reactstrap";
import useTimeout from "hooks/useTimeout";
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { getDeviceParameter, getSupportedLanguages, postActiveLanguages } from "API"; // Import the new function
import { commands } from "variables/commands";

const DeviceConfigCommandRemoteActiveLanguages = ({ serial }) => {
    const [currentActiveLanguages, setCurrentActiveLanguages] = useState(null);
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState('');

    const { data: supportedLanguagesData, isLoading: isLoadingSupportedLanguages } = useQuery('supportedLanguages', () => getSupportedLanguages(serial), {
        refetchOnWindowFocus: false
    });

    const dataConfigActiveLanguages = useQuery('ActiveLanguages', () => getDeviceParameter(serial, commands.active_languages), {
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            const formattedLanguages = data.data.value.replace(/,/g, ', ');
            setCurrentActiveLanguages(formattedLanguages);
        }
    });

    const handleLanguageChange = (event) => {
        setSelectedLanguage(event.target.value);
    };

    const addLanguage = () => {
        if (selectedLanguage && !selectedLanguages.includes(selectedLanguage)) {
            setSelectedLanguages([...selectedLanguages, selectedLanguage]);
            setSelectedLanguage('');  // Reset the selected language
        }
    };

    const removeLanguage = (languageToRemove) => {
        setSelectedLanguages(selectedLanguages.filter(lang => lang !== languageToRemove));
    };

    const queryClient = useQueryClient();
    const { mutate: mutationActiveLanguages, isLoading: mutationActiveLanguagesLoading, isError: mutationActiveLanguagesError, isSuccess: mutationActiveLanguagesSuccess, reset: mutationActiveLanguagesReset } = useMutation(
        {
            mutationFn: postActiveLanguages,
            onSuccess: () => {
                // Invalidate and refetch
                timeoutClearDataPost();
                queryClient.invalidateQueries({ queryKey: ['ActiveLanguages'] });
                queryClient.invalidateQueries({ queryKey: ['Commands'] })
            },
            onError: () => {
                // Invalidate and refetch
                timeoutClearDataPost();
                queryClient.invalidateQueries({ queryKey: ['ActiveLanguages'] });
                queryClient.invalidateQueries({ queryKey: ['Commands'] })
            },
        }
    );

    const handleSubmit = (event) => {
        event.preventDefault();
        if (mutationActiveLanguagesError === true || mutationActiveLanguagesLoading === true) {
            return false
        }

        if (selectedLanguages.length > 0) {
            mutationActiveLanguages({
                serial: serial,
                activeLanguages: selectedLanguages
            });
        } else {
            return false;
        }
    };

    const [timeoutClearDataPost] = useTimeout(() => {
        setSelectedLanguages([]);
        mutationActiveLanguagesReset();
    }, 3000);

    return (
        <>
            <Col lg="12" md="12">
                <Card className="card-stats">
                    <CardBody>
                        <Row>
                            <Col xs="3">
                                <div className="info-icon text-center icon-success">
                                    <i className="tim-icons icon-caps-small" />
                                </div>
                            </Col>
                            <Col xs="9">
                                <div className="numbers">
                                    <p className="card-category">Command</p>
                                    <CardTitle tag="h4">
                                        {currentActiveLanguages === null ? 'Active Languages' : `Active Languages : ${currentActiveLanguages}`}
                                    </CardTitle>
                                </div>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col md='12' lg='6' className="flex-el-bottom-left">
                                {!isLoadingSupportedLanguages && supportedLanguagesData && (
                                    <div>
                                        <select
                                            value={selectedLanguage}
                                            onChange={handleLanguageChange}
                                            className="form-control custom-select"
                                        >
                                            <option value="" disabled>-- Select language --</option>
                                            {supportedLanguagesData.data.supported_languages.map(lang => (
                                                <option key={lang} value={lang}>{lang}</option>
                                            ))}
                                        </select>
                                        <Button onClick={addLanguage} color='primary' style={{ marginTop: '10px' }}>Add Language</Button>
                                    </div>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col md='12' lg='6' className="flex-el-bottom-center">
                                <div className="form-control" id="Languages-container">
                                    {selectedLanguages.map((lang, index) => (
                                        <span key={index} className="badge badge-primary mr-1 custom-badge">
                                            {lang} <Button close aria-label="Cancel" onClick={() => removeLanguage(lang)}>
                                                <span aria-hidden>&times;</span>
                                            </Button>
                                        </span>
                                    ))}
                                </div>
                            </Col>
                            <Col md='12' lg='6' className="date-submit-center">
                                <div className='submit-button-row'>
                                    <Button className="btn-fill" disabled={selectedLanguages.length === 0} color={mutationActiveLanguagesError ? "danger" : mutationActiveLanguagesSuccess ? "success" : 'info'} type="submit"
                                        onClick={(event) => handleSubmit(event)}
                                    >
                                        {mutationActiveLanguagesLoading ? <Spinner size="sm" /> :
                                            mutationActiveLanguagesError ? (
                                                <div>
                                                    Failure <i className="tim-icons icon-simple-remove" />
                                                </div>
                                            ) : mutationActiveLanguagesSuccess ? (
                                                <div>
                                                    Success <i className="tim-icons icon-check-2" />
                                                </div>
                                            ) :
                                                `Submit`
                                        }
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </>
    );
};

export default DeviceConfigCommandRemoteActiveLanguages;
