import React, { useState } from 'react';
import { getDeviceFails } from "API";
import { useQuery } from 'react-query';
import { Doughnut } from "react-chartjs-2";
import SuspenseComponent from '../../components/SuspenseComponent';
import {
    Card,
    CardBody,
    CardTitle,
    Row,
    Col,
    CardHeader,
} from "reactstrap";
const DeviceLogsFailsDoughnut = ({ serial, valueDateRange, loading }) => {

    const [data, setData] = useState([]);
    const chartExample9 = {
        type: 'doughnut',
        data: {
            labels: data.map(item => `${item.message}`),
            datasets: [{
                label: "Emails",
                data: data.map(item => `${item.count}`),
                backgroundColor: ["#00c09d", "#614eca", "#9155be", "#e14eca", "#523eca", "#a5e3d6", "#2c3aca"],
                borderWidth: 0,
            }]
        },
        options: {
            cutout: '50%',  // Adjust this value to make the doughnut smaller
            maintainAspectRatio: false,
            plugins: {
                legend: { 
                    display: true,
                    position: 'right',
                    labels: {
                        boxWidth: 20, 
                        padding: 15, 
                    } 
                }
            },
            scales: {
                y: { display: false },
                x: {
                    barPercentage: 1.6,
                    grid: { display: false, borderColor: "rgba(255,255,255,0.1)" },
                    ticks: { display: false }
                }
            }
        }
    };
    const dataFails = useQuery('Fails', () => getDeviceFails(serial, valueDateRange[0], valueDateRange[1]), {
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            setData(data.data.fails);
        }
    });

    return (
        <>
            {
                loading || dataFails.isLoading || dataFails.isFetching ? <SuspenseComponent /> :
                    <Card className="card-chart">
                        <CardHeader className='mt-4'>
                        <CardTitle tag="h4">Fails by type</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <div className="chart-area">
                                <Doughnut
                                    data={chartExample9.data}
                                    options={chartExample9.options}
                                />
                            </div>
                        </CardBody>
                    </Card>
            }
        </>


    )
}

export default DeviceLogsFailsDoughnut;