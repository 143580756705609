import React, { useEffect, useState } from "react";
import { getInsights } from "API";
import { useQuery } from "react-query";
import SuspenseComponent from "../../components/SuspenseComponent";
import ReactTable from "components/ReactTable/ReactTableConfig.js";
import moment from "moment";
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  CardHeader,
  Button,
} from "reactstrap";
import * as XLSX from "xlsx";

const InsightsTable = ({ valueDateRange, loading }) => {
  const [data, setData] = useState([]);
  const dataInsights = useQuery(
    "InsightsData",
    () => getInsights(valueDateRange[0], valueDateRange[1]),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setData(
          Object.entries(data.data.sales).map(([machine, details]) => {
            return {
              machine: machine,
              sales: details["buy/rent"],
              swap: details.swap_paid + details.swap,
              return: details.return,
              return_fail: details.fails,
              qr_scans: details.qr_scans,
              swap_fails: details.swap_fails,
            };
          })
        );
      },
    }
  );

  const getTrProps = (rowInfo) => {
    return {
      style: {
        background: "green",
        color: "white",
      },
    };
  };

  const convertToExcel = (objArray) => {
    const array =
      typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    const headers = ["Machine", "Sales", "Swaps", "Returns", "Return Fail", "QRs scanned"];
    const dataWithHeaders = [
      headers,
      ...array.map((item) => [
        item.machine,
        item.sales,
        item.swap,
        item.return,
        item.return_fail,
        item.qr_scans,
        item.swap_fails,
      ]),
    ];
    const worksheet = XLSX.utils.aoa_to_sheet(dataWithHeaders);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Insights");

    return workbook;
  };

  const exportToExcel = () => {
    const formatDate = (date) => {
      const d = new Date(date);
      const day = String(d.getDate()).padStart(2, "0");
      const month = String(d.getMonth() + 1).padStart(2, "0");
      const year = String(d.getFullYear());
      return `${day}-${month}-${year}`;
    };
    const startDate = formatDate(valueDateRange[0]);
    const endDate = formatDate(valueDateRange[1]);
    const workbook = convertToExcel(data);
    XLSX.writeFile(workbook, `Insights_report_${startDate}_to_${endDate}.xlsx`);
  };

  return (
    <Row>
      <Col xs={12} md={12}>
        <Card>
          <CardHeader>
            <CardTitle tag="h4">Insights Data</CardTitle>
          </CardHeader>

          <CardBody>
            {loading || dataInsights.isLoading || dataInsights.isFetching ? (
              <SuspenseComponent />
            ) : (
              <div className="insights-table">
                <Button
                  className="btn-fill"
                  color="primary"
                  type="button"
                  onClick={() => exportToExcel()}
                >
                  Export to Excel
                </Button>
                <ReactTable
                  data={data}
                  filterable
                  resizable={false}
                  columns={[
                    {
                      Header: "Machine",
                      sortable: true,
                      accessor: "machine",
                    },
                    {
                      Header: "Sales",
                      sortable: true,
                      accessor: "sales",
                    },

                    {
                      Header: "Swaps",
                      sortable: true,
                      accessor: "swap",
                    },
                    {
                      Header: "Returns",
                      sortable: true,
                      accessor: "return",
                    },
                    {
                      Header: "Return fail",
                      sortable: true,
                      accessor: "return_fail",
                    },
                    {
                      Header: "Swap fail",
                      sortable: true,
                      accessor: "swap_fails",
                    },
                    {
                      Header: "QRs scanned",
                      sortable: true,
                      accessor: "qr_scans",
                    },
                    {
                      Header: "",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  defaultPheartbeatSize={10}
                  showPaginationTop
                  showPaginationBottom={false}
                  getTrProps={getTrProps}
                  className="-striped -highlight"
                />
              </div>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default InsightsTable;
