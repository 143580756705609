import React, { useState } from 'react';
import { getDeviceItemDetails } from "API";
import { useQuery } from 'react-query';
import SuspenseComponent from '../../../views/components/SuspenseComponent';
import ReactTable from "components/ReactTable/ReactTableConfig.js";
import moment from 'moment';
import SuspenseComponentText from '../../../views/components/SuspenseComponentText';
import {
    Card,
    CardBody,
    CardTitle,
    Row,
    Col,
    CardHeader,
    Button
} from "reactstrap";

const dataTest = {
    data:
        [{
            id: 1,
            command_name: 'Reboot Device',
            command_status: '1',
            start_date: "2022-12-12 22:22:22",
            realization_date: "",
            author: 'adam@spadam.pl',
            command_value: '1',
        },
        {
            id: 2,
            command_name: 'Open Locker',
            command_status: '0',
            start_date: "2022-12-12 22:22:22",
            realization_date: "2022-12-12 23:23:23",
            author: 'adam@spadam.pl',
            command_value: '1',
        },
        {
            id: 3,
            command_name: 'Open Locker',
            command_status: '-1',
            start_date: "2022-12-12 22:22:22",
            realization_date: "",
            author: 'adam@spadam.pl',
            command_value: '1',
        }]
}


const ItemDetailsTable = ({ serial, loading, itemid, itemName }) => {
    const [data, setData] = useState([]);
    const dataItemDetails = useQuery('DataItemDetails', () => getDeviceItemDetails(serial, itemid), {
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            setData(
                data.data.item_history?.map((prop, key) => {
                    return {
                        id: prop.user_id,
                        user_id: prop.user_id,
                        quantity: prop.quantity,
                        date: moment(prop.date).format('yyyy-MM-DD hh:mm:ss') === 'Invalid date' ? '-' : moment(prop.date).format('yyyy-MM-DD hh:mm:ss'),
                        message: prop.message,
                        first_name: prop.first_name,
                        second_name: prop.second_name,
                        actions: (
                            // we've added some custom button actions
                            <div className="actions-right">
                                {/* {prop.command_status === -1 ? null :
                                    <Button color="danger"
                                        onClick={() => handleDelete(prop.id)}
                                    >
                                        Delete
                                    </Button>
                                } */}

                            </div>
                        )
                    };
                })
            );
        }
    });

    return (

        <Row>
            <Col xs={12} md={12}>
                <Card>
                    <CardHeader>
                        <CardTitle tag="h4">Item Details for {itemName}</CardTitle>
                    </CardHeader>
                    <CardBody>

                        {
                            loading || dataItemDetails.isLoading || dataItemDetails.isFetching ? <SuspenseComponent /> :
                                <div className='item-details-table'>

                                    <ReactTable
                                        data={data}
                                        filterable
                                        resizable={false}
                                        columns={[
                                            {
                                                Header: "User Id",
                                                accessor: "user_id"
                                            },
                                            {
                                                Header: "Quantity",
                                                accessor: "quantity"
                                            },
                                            {
                                                Header: "Date",
                                                accessor: "date"
                                            },
                                            {
                                                Header: "Message",
                                                accessor: "message"
                                            },
                                            {
                                                Header: "First Name",
                                                accessor: "first_name"
                                            },
                                            {
                                                Header: "Second Name",
                                                accessor: "second_name"
                                            },
                                            {
                                                Header: "",
                                                accessor: "actions",
                                                sortable: false,
                                                filterable: false
                                            }

                                        ]}
                                        defaultPheartbeatSize={10}
                                        showPaginationTop
                                        showPaginationBottom={false}
                                        className="-striped -highlight"
                                    />
                                </div>
                        }


                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default ItemDetailsTable;