/*eslint-disable*/

import React, { useEffect, useState } from 'react';

import {
  useTable,
  useFilters,
  useAsyncDebounce,
  useSortBy,
  usePagination,
} from "react-table";
import { UncontrolledCollapse, Button, CardBody, Card } from "reactstrap";
import classnames from "classnames";
// A great library for fuzzy filtering/sorting items
import { matchSorter } from "match-sorter";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";
// reactstrap components
import { FormGroup, Input, Row, Col } from "reactstrap";
import CustomCollapse from './CustomCollapse';

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter, isNamedPlaceholder, Header, showColumn },
}) {
  const count = preFilteredRows.length;
  return (
    <FormGroup>
      <Input
        type="email"
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={isNamedPlaceholder ? Header : `Search ${count} records...`}
      />
    </FormGroup>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function Table({ columns, data }) {

  // State to store the current window width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Event listener callback function
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    // Add event listener when the component mounts
    window.addEventListener('resize', handleResize);

    // Remove event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array ensures that the effect runs once on mount

  const [numberOfRows, setNumberOfRows] = React.useState(10);
  const [pageSelect, handlePageSelect] = React.useState(0);
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );


  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    visibleColumns,
    nextPage,
    pageOptions,
    pageCount,
    previousPage,
    canPreviousPage,
    canNextPage,
    setPageSize,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: { pageSize: 100, pageIndex: 0 },
    },
    useFilters, // useFilters!
    useSortBy,
    usePagination
  );

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  // const firstPageRows = rows.slice(0, 10);
  let pageSelectData = Array.apply(
    null,
    Array(pageOptions.length)
  ).map(function () { });
  let numberOfRowsData = [5, 10, 20, 25, 50, 100];

  const goToMachine = (dashboardSerial) => {
    if (dashboardSerial) {
      document.location.href = `/user/device/details/${dashboardSerial}`
    };
  }

  const handleCellStyleByColumnStatusAndQuantity = (columnStatus, columnQuantity) => {
    if (columnStatus === 0) {
      return { color: 'red' }
    }
    else {
      if (columnQuantity < 10) {
        return { color: 'orange' }
      }
      else {
        return { color: 'green' }
      }
    }
  }

  return (
    <>
      <div className="ReactTable -striped -highlight " >
        <div className="pagination-top">
          <div className="-pagination">
            <div className="-previous">
              <button
                type="button"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
                className="-btn"
              >
                Previous
              </button>
            </div>
            <div className="-center flex-nowrap">
              <Select
                className="react-select info mx-5 w-100"
                classNamePrefix="react-select"
                name="singleSelect"
                value={pageSelect}
                onChange={(value) => {
                  gotoPage(value.value);
                  handlePageSelect(value);
                }}
                options={pageSelectData.map((prop, key) => {
                  return {
                    value: key,
                    label: "Page " + (key + 1),
                  };
                })}
                placeholder="Select page"
              />
              <Select
                className="react-select info mx-5 w-100"
                classNamePrefix="react-select"
                name="singleSelect"
                value={numberOfRows}
                onChange={(value) => {

                  setPageSize(value.value);
                  setNumberOfRows(value);
                }}
                options={numberOfRowsData.map((prop) => {
                  return {
                    value: prop,
                    label: prop + " rows",
                  };
                })}
                placeholder="Select #rows"
              />
            </div>
            <div className="-next">
              <button
                type="button"
                onClick={() => nextPage()}
                disabled={!canNextPage}
                className="-btn"
              >
                Next
              </button>
            </div>
          </div>
        </div>
        <table {...getTableProps()} className="rt-table">

          <thead className="rt-thead -header">
            {/* <tr>
              <td>
                <CustomCollapse>{headerGroups.map((headerGroup, i) => {
                
                  return <React.Fragment key={i}>{
                    headerGroup.headers.map((column, key) => {
                      return (
                        <React.Fragment key={key}>

                          {headerGroup.headers.length - 1 === key
                            ? null
                            : column.canFilter
                              ? column.render("Filter")
                              : null}
                        </React.Fragment>
                      )
                    }
                    )
                  }
                  </React.Fragment>
                })}</CustomCollapse>

              </td>

            </tr> */}
            {(headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} className="rt-tr">
                {headerGroup.headers.map((column, key) => {
                  return (<th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={classnames("rt-th rt-resizable-header", {
                      "-cursor-pointer": headerGroup.headers.length - 1 !== key,
                      "-sort-asc": column.isSorted && !column.isSortedDesc,
                      "-sort-desc": column.isSorted && column.isSortedDesc,
                    })}

                  >

                    <div className="rt-resizable-header-content">
                      {column.render("Header")}
                    </div>
                    {/* Render the columns filter UI */}

                    <div>
                      {headerGroup.headers.length - 1 === key
                        ? null
                        : column.canFilter
                          ? column.render("Filter")
                          : null}
                    </div>
                  </th>)
                })}
              </tr>
            )))}
            { }
          </thead>
          <tbody {...getTableBodyProps()} className="rt-tbody">
            {(page.map((row, i) => {
              let rowStyle = {}
              if (row.original.lvl !== undefined) {
                if (0 <= row.original.lvl && row.original.lvl < 100) {
                  rowStyle = { backgroundColor: 'black' }
                }
                else if (100 <= row.original.lvl && row.original.lvl < 200) {
                  rowStyle = { backgroundColor: 'black' }
                }
                else if (200 <= row.original.lvl && row.original.lvl < 300) {
                  rowStyle = { backgroundColor: 'blue' }
                }
                else if (300 <= row.original.lvl && row.original.lvl < 400) {
                  rowStyle = { backgroundColor: 'red' }
                }
                else if (400 <= row.original.lvl && row.original.lvl < 500) {
                  rowStyle = {}
                }
              }

              prepareRow(row);
              const { expired, dashboardSerial, lowest_log_lvl, column4_units, column3_units, column2_units, column1_units, column1_status, column2_status, column3_status, column4_status } = row.original;

              return (

                <tr
                  {...row.getRowProps()}
                  className={classnames(
                    "rt-tr",
                    { " -odd": i % 2 === 0 },
                    { " -even": i % 2 === 1 },
                  )}

                  style={rowStyle ? { ...rowStyle } : { ...rowStyle }}
                >

                  {row.cells.map((cell) => {
                    let CellCurrentStyle = {}
                    let CellContent;
                    if (cell.column.id === 'column1_units') {
                      CellCurrentStyle = handleCellStyleByColumnStatusAndQuantity(column1_status, cell.value)
                    }
                    else if (cell.column.id === 'column2_units') {
                      CellCurrentStyle = handleCellStyleByColumnStatusAndQuantity(column2_status, cell.value)
                    }
                    else if (cell.column.id === 'column3_units') {
                      CellCurrentStyle = handleCellStyleByColumnStatusAndQuantity(column3_status, cell.value)
                    }
                    else if (cell.column.id === 'column4_units') {
                      CellCurrentStyle = handleCellStyleByColumnStatusAndQuantity(column4_status, cell.value)
                    }
                    else if (cell.column.id === 'total_empty_units') {
                      if (cell.value > 110) {
                        CellCurrentStyle = { color: 'red' }
                      }
                      else if (cell.value > 80) {
                        CellCurrentStyle = { color: 'orange' }
                      }
                    }
                    else if (cell.column.id === 'device_status') {
                      if (cell.value === 'OK') {
                        CellCurrentStyle = { color: 'green' }
                      }
                      else {
                        CellCurrentStyle = { color: 'red' }
                      }

                      CellContent = (
                        <div className='column-status-wrapper'>
                          <div className={column1_status === 0 ? `column-status-wrapper-diod column-status-wrapper-diod-inactive` : `column-status-wrapper-diod column-status-wrapper-diod-active`}></div>
                          <div className={column2_status === 0 ? `column-status-wrapper-diod column-status-wrapper-diod-inactive` : `column-status-wrapper-diod column-status-wrapper-diod-active`}></div>
                          <div className={column3_status === 0 ? `column-status-wrapper-diod column-status-wrapper-diod-inactive` : `column-status-wrapper-diod column-status-wrapper-diod-active`}></div>
                          <div className={column4_status === 0 ? `column-status-wrapper-diod column-status-wrapper-diod-inactive` : `column-status-wrapper-diod column-status-wrapper-diod-active`}></div>
                        </div>
                      )
                    }
                    else if (cell.column.id === 'heartbeat' || cell.column.id === 'dashboardName') {
                      if (expired === true) {
                        CellCurrentStyle = { color: 'red' }
                      }
                      else {
                        CellCurrentStyle = { color: 'green' }
                      }
                    }
                    else if (cell.column.id === 'dashboardName' || cell.column.id === 'dashboardSerial') {
                      // if (lowest_log_lvl !== undefined) {
                      //   if (0 <= lowest_log_lvl && lowest_log_lvl < 100) {
                      //     CellCurrentStyle = { color: 'black', fontWeight: 'bold', textShadow: '-1px 1px 0 grey,  1px 1px 0 grey,  1px -1px 0 grey,  -1px -1px 0 grey' }
                      //   }else if (100 <= lowest_log_lvl && lowest_log_lvl < 200) {
                      //     CellCurrentStyle = { color: 'rgba(36, 0, 0, 0.9)', fontWeight: 'bold', textShadow:`0 0 1px rgb(61, 61, 61), 0 0 17px rgb(139, 139, 139)`}
                      //   }
                      //   else if (200 <= lowest_log_lvl && lowest_log_lvl < 300) {
                      //     CellCurrentStyle = {color: 'inherit' }
                      //   }
                      //   else if (300 <= lowest_log_lvl && lowest_log_lvl < 400) {
                      //     CellCurrentStyle = { color: 'red', fontWeight: 'bold' }
                      //   }
                      //   else if (400 <= lowest_log_lvl && lowest_log_lvl < 500 && !expired) {
                      //     CellCurrentStyle = {color: 'green'}
                      //   }
                      // }
                    }
                    return (
                      <td {...cell.getCellProps()}
                        className="rt-td"
                        style={{ ...CellCurrentStyle }}
                        onClick={() => goToMachine(dashboardSerial)}
                      >
                        {CellContent === undefined ? cell.render("Cell") : CellContent}
                      </td>
                    );
                  })}

                </tr>

              );
            }))}
          </tbody>
        </table>
        <div className="pagination-bottom"></div>
      </div>
    </>
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";

export default Table;
