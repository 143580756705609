/*!

=========================================================
* Black Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// import classnames from "classnames";
// import { NavLink } from "react-router-dom";

// // reactstrap components
// import {
//   Collapse,
//   NavbarBrand,
//   Navbar,
//   NavItem,
//   Nav,
//   Container
// } from "reactstrap";

const AuthNavbar = (props) => {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [color, setColor] = React.useState("navbar-transparent");
  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  const toggleCollapse = () => {
    if (collapseOpen) {
      setColor("navbar-transparent");
    } else {
      setColor("bg-white");
    }
    setCollapseOpen(!collapseOpen);
  };
  return (
    null
    // <Navbar
    //   className={classnames("navbar-absolute fixed-top", color)}
    //   expand="lg"
    // >
    //   <Container fluid>
    //     <div className="navbar-wrapper">
    //       <NavbarBrand href="#pablo" onClick={(e) => e.preventDefault()}>
    //         {props.brandText}
    //       </NavbarBrand>
    //     </div>
    //     <button
    //       aria-controls="navigation-index"
    //       aria-expanded={false}
    //       aria-label="Toggle navigation"
    //       className="navbar-toggler"
    //       data-toggle="collapse"
    //       type="button"
    //       onClick={toggleCollapse}
    //     >
    //       <span className="navbar-toggler-bar navbar-kebab" />
    //       <span className="navbar-toggler-bar navbar-kebab" />
    //       <span className="navbar-toggler-bar navbar-kebab" />
    //     </button>
    //     <Collapse isOpen={collapseOpen} navbar>
    //       <Nav navbar className="ml-auto">
    //         <NavItem>
    //           <NavLink to="/admin/dashboard" className="nav-link text-primary">
    //             <i className="tim-icons icon-minimal-left" /> Back to Dashboard
    //           </NavLink>
    //         </NavItem>
    //         <NavItem>
    //           <NavLink to="/auth/register" className="nav-link">
    //             <i className="tim-icons icon-laptop" /> Register
    //           </NavLink>
    //         </NavItem>
    //         <NavItem>
    //           <NavLink to="/auth/login" className="nav-link">
    //             <i className="tim-icons icon-single-02" /> Login
    //           </NavLink>
    //         </NavItem>
    //         <NavItem>
    //           <NavLink to="/auth/pricing" className="nav-link">
    //             <i className="tim-icons icon-coins" /> Pricing
    //           </NavLink>
    //         </NavItem>
    //         <NavItem>
    //           <NavLink to="/auth/lock-screen" className="nav-link">
    //             <i className="tim-icons icon-lock-circle" /> Lock
    //           </NavLink>
    //         </NavItem>
    //       </Nav>
    //     </Collapse>
    //   </Container>
    // </Navbar>
  );
};

export default AuthNavbar;
