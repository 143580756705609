import React, { useEffect } from "react";
import { Button, Input, InputAdornment, InputLabel, FormControl } from "@mui/material"
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"



const LoginForm = ({ handleLogin, isLoading, isError }) => {
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const schema = yup
        .object({
            username: yup.string().required(),
            password: yup.string().required(),
        })
        .required()
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ resolver: yupResolver(schema) });

    const handleMouseDownPassword = event => event.preventDefault();
    const onSubmit = (data) => handleLogin(data)

    return (
        <>
            <div className="login_form_container custom-main-card">
                <h1
                    component='h1'
                    className="login_form_title"
                >
                    Sign in
                </h1>
                <div className="form_container_img">
                    <a href="https://www.exa22.com">
                        <img alt="exa22_logo" src={require("assets/img/exa22_logo.png")} />
                    </a>
                </div>
                <form
                    className="auth_form"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <FormControl
                        fullWidth
                        variant="standard"
                    >
                        <InputLabel
                            htmlFor="auth-form-emaiil"
                            color={errors.username ? 'error' : 'primary'}
                        >
                            {errors.username ? errors.username.message : 'Username'}
                        </InputLabel>
                        <Input
                            {...register("username", { required: true, minLength: 2 })}
                            color={errors.username ? 'error' : 'primary'}
                            id="auth-form-emaiil"
                            variant="standard"
                            label='Username'
                            sx={{
                                mb: 2,
                            }}
                        />
                    </FormControl>
                    <FormControl fullWidth variant="standard">
                        <InputLabel
                            htmlFor="auth-form-password"
                            color={errors.password ? 'error' : 'primary'}>
                            {errors.password ? errors.password.message : 'Password'}
                        </InputLabel>
                        <Input
                            {...register("password", { required: true, minLength: 5 })}
                            variant="standard"
                            color={errors.password ? 'error' : 'primary'}
                            id="auth-form-password"
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <Button
                        variant="contained"
                        color='secondary'
                        type="submit"
                        size='small'
                        disabled={!!isLoading || !!isError || false}
                        sx={{
                            mb: 3,
                            mt: 8,
                            backgroundColor: isError
                              ? 'linear-gradient(315deg, #A71D31 5%, #3F0D12 74%)'
                              : 'black',
                            padding: 2,
                            '&:hover': {
                              backgroundColor: isError
                                ? 'linear-gradient(315deg, #A71D31 5%, #3F0D12 74%)'
                                : 'black',
                            },
                          }}

                    >
                        {isLoading && <CircularProgress size={18} sx={{ mr: 2, color: '#ffffff' }} />}
                        <span style={{ color: '#ECF200', 'fontWeight' : '800' }}>{isLoading ? 'Loading...' : isError ? 'Not correct data' : 'Get started'}</span>
                    </Button>
                </form>
                <div className="auth_form_footer">
                    <div><a href="#">CREATE ACCOUNT</a></div>
                    <div><a href="https://www.exa22.com/kontakt/">NEED HELP?</a></div>
                </div>
            </div>
        </>
    )
}
export default LoginForm