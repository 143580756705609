import React, { useState } from "react";
import {
    Card,
    CardBody,
    CardTitle,
    Row,
    Col,
    CardFooter,
} from "reactstrap";
import moment from 'moment';
import useHeartbeatCheck from '../../../hooks/useHeartbeatCheck';
// reactstrap components
import { useQuery } from 'react-query';
import { getDeviceHeartbeat } from "API";
import SuspenseComponentText from '../../../views/components/SuspenseComponentText';
const DeviceDetailsHeartbeat = ({ serial }) => {
    const [dataHeartbeat, setDataHeartbeat] = useState(null);
    const dataGetHeartbeat = useQuery('HeartBeat', () => getDeviceHeartbeat(serial), {
        refetchInterval: 60000,
        onSuccess: (data) => {
            setDataHeartbeat(moment.utc(data.data.heartbeat).local().format("YYYY-MM-DD HH:mm:ss"));
        }
    });
    const isOnline = useHeartbeatCheck(dataHeartbeat);

    return (
        <>
            <Col lg="6" md="6">
                <Card className="card-stats">
                    <CardBody>
                        <Row>
                            <Col xs="5">
                                <div className="info-icon text-center icon-success">
                                    <i className="tim-icons icon-alert-circle-exc" />
                                </div>
                            </Col>
                            <Col xs="7">
                                <div className="numbers">
                                    <p className="card-category">Heartbeat</p>
                                    <CardTitle tag="h3">
                                        {dataGetHeartbeat.isLoading ? <SuspenseComponentText /> :
                                            dataGetHeartbeat.isError ? `Error` : isOnline
                                        }
                                    </CardTitle>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter>
                        <hr />
                        <div className="stats">
                            <i className="tim-icons icon-alert-circle-exc" />
                            {dataGetHeartbeat.isLoading ? <SuspenseComponentText /> :
                                dataGetHeartbeat.isError ? `Error` : dataHeartbeat
                            }
                        </div>
                    </CardFooter>
                </Card>
            </Col>
        </>
    )
}

export default DeviceDetailsHeartbeat;