import React, { useState } from "react";
import {
    Card,
    CardBody,
    CardTitle,
    Row,
    Col,
    Button,
    Input
} from "reactstrap";
import SuspenseComponentText from '../../../views/components/SuspenseComponentText';
import useTimeout from "hooks/useTimeout";
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { postDeviceConfigCommand, getDeviceParameter } from "API";
import { commands } from "variables/commands";


const DeviceConfigCommandRemoteCurrency = ({ serial }) => {
    const [currentCurrency, setCurrentCurrency] = useState(null);
    const [currency, setCurrency] = useState("");

    const dataConfigCurrency = useQuery('Currency', () => getDeviceParameter(serial, commands.currency), {
        refetchOnWindowFocus: false,
        onSuccess: (data) => {

            setCurrentCurrency(data.data.value)

        }
    });


    const handleCurrencyChange = (event) => {
        setCurrency(event.target.value);
    };

    const queryClient = useQueryClient()
    const { mutate: mutationConfigCommand, isLoading: mutationConfigCommandLoading, isError: mutationConfigCommandError, isSuccess: mutationConfigCommandSuccess, reset: mutationConfigCommandReset } = useMutation(
        {
            mutationFn: postDeviceConfigCommand,
            onSuccess: () => {
                // Invalidate and refetch
                timeoutClearDataPost();
                queryClient.invalidateQueries({ queryKey: ['Commands'] })
                queryClient.invalidateQueries({ queryKey: ['Currency'] })


            },
            onError: () => {
                // Invalidate and refetch
                timeoutClearDataPost();
                queryClient.invalidateQueries({ queryKey: ['Commands'] })

            },
        }
    );

    const currencyRegexCondition = currency.length !== 0 && /^[^\d]*$/.test(currency);

    const handleSubmit = (event) => {
        event.preventDefault();
        if (mutationConfigCommandError === true || mutationConfigCommandLoading === true) {
            return false
        }
        
        // check whether currency is valid
        if (currencyRegexCondition) {
            mutationConfigCommand({
                serial: serial,
                commandName: commands.currency,
                commandValue: currency
            })
        } else {
            return false;
        }
    }

    const [timeoutClearDataPost] = useTimeout(() => {
        setCurrency("")
        mutationConfigCommandReset();
    }, 3000);


    return (
        <>
            <Col lg="6" md="6">
                <Card className="card-stats">
                    <CardBody>
                        <Row>
                            <Col xs="3">
                                <div className="info-icon text-center icon-success">
                                    <i className="tim-icons icon-badge" />
                                </div>
                            </Col>
                            <Col xs="9">
                                <div className="numbers">
                                    <p className="card-category">Command</p>
                                    <CardTitle tag="h4">

                                        {currentCurrency === null ? 'Change Currency' : `Change Currency : ${currentCurrency}`}
                                    </CardTitle>
                                </div>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col md='12' lg='6' className="flex-el-bottom-center">
                                <Input
                                    type="text"
                                    value={currency}
                                    onChange={handleCurrencyChange}
                                    minLength={1}
                                    maxLength={3}
                                />
                            </Col>
                            <Col md='12' lg='6' className="date-submit-center">
                                <div className='submit-button-row'>
                                    <Button className="btn-fill" disabled={!currencyRegexCondition ? true : false} color={mutationConfigCommandError ? "danger" : mutationConfigCommandSuccess ? "success" : 'info'} type="submit"
                                        onClick={(event) => handleSubmit(event)}
                                    >
                                        {mutationConfigCommandLoading ? <SuspenseComponentText /> :
                                            mutationConfigCommandError ? (
                                                <div>
                                                    Failure <i className="tim-icons icon-simple-remove" />
                                                </div>
                                            ) : mutationConfigCommandSuccess ? (
                                                <div>
                                                    Success <i className="tim-icons icon-check-2" />
                                                </div>
                                            ) :
                                                `Submit`
                                        }
                                    </Button>
                                </div>
                            </Col>

                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </>
    )
}

export default DeviceConfigCommandRemoteCurrency;