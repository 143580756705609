import React, { useState } from 'react';
import { getDeviceCommands } from "API";
import { useQuery } from 'react-query';
import SuspenseComponent from '../../../views/components/SuspenseComponent';
import ReactTable from "components/ReactTable/ReactTableConfig.js";
import moment from 'moment';
import { useMutation, useQueryClient } from 'react-query';
import SuspenseComponentText from '../../../views/components/SuspenseComponentText';
import { postDeviceConfigDelete, postDeviceConfigCommand } from "API";
import NotificationAlert from "react-notification-alert";

import {
    Card,
    CardBody,
    CardTitle,
    Row,
    Col,
    CardHeader,
    Button
} from "reactstrap";
import useTimeout from "hooks/useTimeout";


const dataTest = {
    data:
        [{
            id: 1,
            command_name: 'Reboot Device',
            command_status: '1',
            start_date: "2022-12-12 22:22:22",
            realization_date: "",
            author: 'adam@spadam.pl',
            command_value: '1',
        },
        {
            id: 2,
            command_name: 'Open Locker',
            command_status: '0',
            start_date: "2022-12-12 22:22:22",
            realization_date: "2022-12-12 23:23:23",
            author: 'adam@spadam.pl',
            command_value: '1',
        },
        {
            id: 3,
            command_name: 'Open Locker',
            command_status: '-1',
            start_date: "2022-12-12 22:22:22",
            realization_date: "",
            author: 'adam@spadam.pl',
            command_value: '1',
        }]
}

const handleRowValue = (rowName, rowValue) => {
    let returnRowValue;
    switch (rowName) {
        case "Change Column 1 State":
            returnRowValue = rowValue === '0' ? "Disable" : "Enable";
            break;
        case "Change Column 2 State":
            returnRowValue = rowValue === '0' ? "Disable" : "Enable";
            break;
        case "Change Column 3 State":
            returnRowValue = rowValue === '0' ? "Disable" : "Enable";
            break;
        case "Change Column 4 State":
            returnRowValue = rowValue === '0' ? "Disable" : "Enable";
            break;
        case "Set Technical Break":
            returnRowValue = rowValue === '0' ? "Disable" : "Enable";
            break;
        case "Set Buy/Rent":
            returnRowValue = rowValue === '0' ? "Disable" : "Enable";
            break;
        case "Set Swap":
            returnRowValue = rowValue === '0' ? "Disable" : "Enable";
            break;
        case "Set Paid Swap":
            returnRowValue = rowValue === '0' ? "Disable" : "Enable";
            break;
        case "Set Return":
            returnRowValue = rowValue === '0' ? "Disable" : "Enable";
            break;
        default:
            returnRowValue = rowValue;
            break;
    }
    return returnRowValue;
}

const DeviceConfigCommandsTable = ({ serial, loading }) => {
    const notificationAlertRef = React.useRef(null);
    const [data, setData] = useState([]);
    const dataCommands = useQuery('Commands', () => getDeviceCommands(serial), {
        refetchOnWindowFocus: false,
        refetchInterval: 60000,
        onSuccess: (data) => {
            setData(
                data.data.command_logs?.map((prop, key) => {
                    return {
                        id: prop.id,
                        commandName: prop.command_name,
                        commandStatus: prop.command_status === 0 ? <p style={{ color: 'red' }}>Not executed</p> : prop.command_status === 1 ? 'Finished' : 'Pending',
                        // startDate: moment(prop.start_date).format('yyyy-MM-DD hh:mm:ss'),
                        realizationDate: moment.utc(prop.realization_date).local().format("YYYY-MM-DD HH:mm:ss") === 'Invalid date' ? '-' : moment.utc(prop.realization_date).local().format("YYYY-MM-DD HH:mm:ss"),
                        author: prop.author,
                        value: handleRowValue(prop.command_name, prop.value),
                        actions: (
                            // we've added some custom button actions
                            <div className="actions-right">
                                {/* {prop.command_status === -1 ? null :
                                    <Button color="danger"
                                        onClick={() => handleDelete(prop.id)}
                                    >
                                        Delete
                                    </Button>
                                } */}

                            </div>
                        )
                    };
                })
            );
        }
    });

    const queryClient = useQueryClient()
    const { mutate: mutationConfigCommandDelete, isLoading: mutationConfigCommandDeleteLoading, isError: mutationConfigCommandDeleteError, isSuccess: mutationConfigCommandDeleteSuccess, reset: mutationConfigCommandDeleteReset } = useMutation(
        {
            mutationFn: postDeviceConfigDelete,
            onSuccess: () => {
                // Invalidate and refetch

                notify("bc", 2, 2)
                timeoutClearDataPost();
                queryClient.invalidateQueries({ queryKey: ['Commands'] })
            },
            onError: () => {

                notify("bc", 3, 1)
                // Invalidate and refetch
                timeoutClearDataPost();
            },
        }
    );

    const [timeoutClearDataPost] = useTimeout(() => {
        mutationConfigCommandDeleteReset();
    }, 3000);

    const handleDelete = (commandId) => {
        if (mutationConfigCommandDeleteError === true || mutationConfigCommandDeleteLoading === true) {
            return false
        }

        mutationConfigCommandDelete({
            commandId: commandId
        })

    }

    const notify = (place, color, message) => {
        var type;
        var messageType;
        switch (color) {
            case 1:
                type = "primary";
                break;
            case 2:
                type = "success";
                break;
            case 3:
                type = "danger";
                break;
            case 4:
                type = "warning";
                break;
            case 5:
                type = "info";
                break;
            default:
                break;
        }
        switch (message) {
            case 1:
                messageType = (<div>
                    <div>
                        Unable to delete current command. <b>Try again later...</b>
                    </div>
                </div>);
                break;
            case 2:
                messageType = (<div>
                    <div>
                        Successfully deleted <b>command</b>.
                    </div>
                </div>);
                break;
            default:
                break;
        }
        var options = {};
        options = {
            place: place,
            message: messageType,
            type: type,
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7
        };
        notificationAlertRef.current.notificationAlert(options);
    };
    return (

        <Row>
            <div className="rna-container">
                <NotificationAlert ref={notificationAlertRef} />
            </div>
            <Col xs={12} md={12}>
                <Card>
                    <CardHeader>
                        <CardTitle tag="h4">Recent Commands</CardTitle>
                    </CardHeader>
                    <CardBody>

                        {
                            loading || dataCommands.isLoading || dataCommands.isFetching ? <SuspenseComponent /> :
                                <div className='config-table'>

                                    <ReactTable
                                        data={data}
                                        filterable
                                        resizable={false}
                                        columns={[
                                            {
                                                Header: "Command name",
                                                accessor: "commandName"
                                            },
                                            {
                                                Header: "Command status",
                                                accessor: "commandStatus"
                                            },
                                            // {
                                            //     Header: "Start realization date",
                                            //     accessor: "startDate",

                                            // },
                                            {
                                                Header: "Realization date",
                                                accessor: "realizationDate"
                                            },
                                            {
                                                Header: "Author",
                                                accessor: "author"
                                            },
                                            {
                                                Header: "Value",
                                                accessor: "value"
                                            },
                                            {
                                                Header: "Actions",
                                                accessor: "actions",
                                                sortable: false,
                                                filterable: false
                                            }

                                        ]}

                                        defaultPheartbeatSize={10}
                                        showPaginationTop
                                        showPaginationBottom={false}
                                        className="-striped -highlight"

                                    />
                                </div>
                        }


                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default DeviceConfigCommandsTable;