import React, { Suspense, useState } from 'react';
import SuspenseComponent from '../../../views/components/SuspenseComponent';
import DeviceBreadcrumbComponent from '../DeviceBreadcrumbComponent';
import DeviceConfigCommandReboot from './DeviceConfigCommandReboot';
import DeviceConfigCommandResetCounter from './DeviceConfigCommandResetCounter';
import DeviceConfigCommandRemoteDispense from './DeviceConfigCommandRemoteDispense';
import DeviceConfigCommandRemoteColour from './DeviceConfigCommandRemoteColour';
import DeviceConfigCommandRemotePrice from './DeviceConfigCommandRemotePrice';
import DeviceConfigCommandRemoteSwapPrice from './DeviceConfigCommandRemoteSwapPrice';
import DeviceConfigCommandRemoteDeposit from './DeviceConfigCommandRemoteDeposit';
import DeviceConfigCommandRemoteCurrency from './DeviceConfigCommandRemoteCurrency';
import DeviceConfigCommandRemoteSupportNumber from './DeviceConfigCommandRemoteSupportNumber';
import DeviceConfigCommandRemoteColumn from './DeviceConfigCommandRemoteColumn';
import DeviceConfigCommandOpenLocker from './DeviceConfigCommandOpenLocker';
import DeviceConfigCommandRemoteMachineType from './DeviceConfigCommandRemoteMachineType';
import DeviceConfigCommandTechnicalBreak from './DeviceConfigCommandTechnicalBreak';
import DeviceConfigCommandRemoteBuyRent from './DeviceConfigCommandRemoteBuyRent';
import DeviceConfigCommandRemoteSwap from './DeviceConfigCommandRemoteSwap';
import DeviceConfigCommandRemoteSwapPaid from './DeviceConfigCommandRemoteSwapPaid';
import DeviceConfigCommandRemoteReturn from './DeviceConfigCommandRemoteReturn';
import DeviceConfigCommandsTable from './DeviceConfigCommandsTable';
import DeviceConfigCommandRemoteCharityName from './DeviceConfigCommandRemoteCharityName';
import DeviceConfigCommandRemoteActiveLanguages from './DeviceConfigCommandRemoteActiveLanguages';
import DeviceConfigCommandRemoteO2config from './DeviceConfigCommandRemoteO2config';
import { Row, Col } from 'reactstrap';
import useSerialCheck from 'hooks/useSerialCheck';
import { useDataDeviceAccess } from 'contexts/DataDeviceContext';
import { useParams } from "react-router-dom";
const DeviceConfig = (props) => {
    let { serial } = useParams();
    const userDataDeviceAccess = useDataDeviceAccess();
    const serialFound = useSerialCheck(userDataDeviceAccess, serial);
    const [loading, setLoading] = useState(null);
    return (
        <>
            <div className="content">
                <Row>
                    <Col lg="3">
                        <h4>
                            {serialFound !== true ? null : `${serial} Config`}
                        </h4>
                    </Col>
                    <Col lg="9">
                        <DeviceBreadcrumbComponent activePath={'DeviceConfig'} serial={serial} />
                    </Col>
                </Row>
                <Suspense fallback={<SuspenseComponent />}>
                    <Row>
                        <DeviceConfigCommandReboot serial={serial} />
                        <DeviceConfigCommandResetCounter serial={serial} />
                    </Row>
                    <Row>
                        <DeviceConfigCommandRemoteDispense serial={serial}/>
                        <DeviceConfigCommandTechnicalBreak serial={serial} />
                    </Row>
                    <Row>
                        <DeviceConfigCommandRemoteBuyRent serial={serial} />
                        <DeviceConfigCommandRemoteSwap serial={serial} />
                    </Row>
                    <Row>
                        <DeviceConfigCommandRemoteReturn serial={serial} />
                        <DeviceConfigCommandRemoteSwapPaid serial={serial} />
                    </Row>
                    <Row>
                        <DeviceConfigCommandRemoteColour serial={serial} />
                        <DeviceConfigCommandRemoteColumn serial={serial} />
                    </Row>
                    <Row>
                        <DeviceConfigCommandRemoteMachineType serial={serial} />
                        <DeviceConfigCommandRemoteCharityName serial={serial} />
                    </Row>
                    <Row>
                        <DeviceConfigCommandRemotePrice serial={serial} />
                        <DeviceConfigCommandRemoteSwapPrice serial={serial} />
                        <DeviceConfigCommandRemoteDeposit serial={serial} />
                    </Row>
                    <Row>
                        <DeviceConfigCommandRemoteCurrency serial={serial} />
                        <DeviceConfigCommandRemoteSupportNumber serial={serial} />
                    </Row>
                    <Row>
                        <DeviceConfigCommandRemoteActiveLanguages serial={serial} />
                    </Row>
                    <DeviceConfigCommandsTable loading={loading} serial={serial} />
                </Suspense>
            </div>
        </>
    );
};

export default DeviceConfig;
