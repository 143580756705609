import React, { useState, useEffect } from "react";
import {
    Card,
    CardBody,
    CardTitle,
    Row,
    Col,
    Button,
    FormGroup,
    Input
} from "reactstrap";
import ReactDatetime from "react-datetime";
import SuspenseComponentText from '../../../views/components/SuspenseComponentText';
import useTimeout from "hooks/useTimeout";
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { postDeviceConfigCommandColor, getDeviceConfigColors } from "API";
import { commands } from "variables/commands";
import Select from "react-select";

// reactstrap components
const DeviceConfigCommandRemoteColour = ({ serial }) => {
    const [realizationDateErrorState, setRealizationDateErrorState] = useState('');
    const [realizationDateValue, setRealizationDateValue] = useState(new Date());
    const [color, setColor] = useState('#000000');
    const [singleSelect, setsingleSelect] = useState({
        value: "",
        label: "Select Colour",
        isDisabled: true,
        state: '#000000'
    });
    const [singleSelectOptions, setSingleSelectOptions] = useState([
        {
            value: "",
            label: "Select Colour",
            isDisabled: true,
            state: '#000000'
        },
        // { value: "fontColor", label: "fontColor", state: '#FFFFFF', },
        // { value: "fontSecondaryColor", label: "fontSecondaryColor", state: '#ffff00', },
        // { value: "fontTertiaryColor", label: "fontTertiaryColor", state: '#058b8c', },
        // { value: "backgroundColor", label: "backgroundColor", state: '#058b8c', },
        // { value: "buttonBackgroundColor", label: "buttonBackgroundColor", state: '#ffff00', }
    ]);

    const dataConfigColors = useQuery('ConfigColors', () => getDeviceConfigColors(serial), {
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            setSingleSelectOptions(prevOptions => {
                const updatedOptions = prevOptions.map(option => {
                    const matchingDataOption = data.data.find(dataOption => dataOption.color_name === option.value);
                    if (matchingDataOption) {
                        return {
                            ...option,
                            label: matchingDataOption.label,
                            state: matchingDataOption.value
                        };
                    }
                    return option;
                });

                const newOptions = data.data.filter(dataOption => {
                    return !prevOptions.some(option => option.value === dataOption.color_name);
                }).map(option => ({
                    value: option.color_name,
                    label: option.label,
                    state: option.value
                }));

                return [...updatedOptions, ...newOptions];
            });
            
        }
    });


    const handleColorChange = (event) => {

        setColor(event.target.value);
    };
    const handleSelectColorChange = (value) => {
        setColor(value.state)
        setsingleSelect(value)
    };
    const queryClient = useQueryClient()
    const { mutate: mutationConfigCommand, isLoading: mutationConfigCommandLoading, isError: mutationConfigCommandError, isSuccess: mutationConfigCommandSuccess, reset: mutationConfigCommandReset } = useMutation(
        {
            mutationFn: postDeviceConfigCommandColor,
            onSuccess: () => {
                // Invalidate and refetch
                timeoutClearDataPost();
                queryClient.invalidateQueries({ queryKey: ['Commands'] })
                queryClient.invalidateQueries({ queryKey: ['ConfigColors'] })
                
            },
            onError: () => {
                // Invalidate and refetch
                timeoutClearDataPost();
                queryClient.invalidateQueries({ queryKey: ['Commands'] })
            },
        }
    );

    const handleSubmit = (event) => {
        event.preventDefault();
        if (mutationConfigCommandError === true || mutationConfigCommandLoading === true) {
            return false
        }
        if (realizationDateValue === '' || typeof realizationDateValue !== 'object') {
            setRealizationDateErrorState("has-danger");
            return false
        }
        if (realizationDateErrorState !== '') {
            return false
        }
        mutationConfigCommand({
            realizationDate: realizationDateValue,
            serial: serial,
            commandName: singleSelect.value,
            commandValue: color
        })
    }

    // const handleRealizationDateValue = (date) => {
    //     if (typeof date !== 'object') {
    //         setRealizationDateErrorState('has-danger');
    //         setRealizationDateValue(date);
    //     }
    //     else {
    //         setRealizationDateErrorState('');
    //         setRealizationDateValue(date._d);
    //     }
    // }

    const [timeoutClearDataPost] = useTimeout(() => {
        mutationConfigCommandReset();
    }, 3000);


    return (
        <>
            <Col lg="6" md="6">
                <Card className="card-stats">
                    <CardBody>
                        <Row>
                            <Col xs="3">
                                <div className="info-icon text-center icon-primary">
                                    <i className="tim-icons icon-palette" />
                                </div>
                            </Col>
                            <Col xs="9">
                                <div className="numbers">
                                    <p className="card-category">Command</p>
                                    <CardTitle tag="h4">

                                        {singleSelect.value === "" ? 'Change Colour' : `Change ${singleSelect.label}`} {singleSelect.value === "" ? "" : `: ${singleSelectOptions.find((color) => color.label === singleSelect.value).state}`}
                                    </CardTitle>
                                </div>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col md='12' lg='4'>
                                <label>Column Select</label>
                                {/* <FormGroup className={realizationDateErrorState}>
                                    <ReactDatetime
                                        inputProps={{
                                            className: "form-control",
                                            placeholder: "Datetime Picker Here",
                                        }}
                                        timeFormat={"HH:mm"}
                                        dateFormat={"YYYY-MM-DD"}
                                        value={realizationDateValue}
                                        onChange={handleRealizationDateValue}
                                    />
                                    {realizationDateErrorState === "has-danger" ? (
                                        <label className="error">
                                            Please enter a valid datetime YYYY-MM-DD HH:mm.
                                        </label>
                                    ) : null}
                                </FormGroup> */}

                                <Select
                                    className="react-select info"
                                    classNamePrefix="react-select"
                                    name="singleSelect"
                                    value={singleSelect}
                                    onChange={(value) => handleSelectColorChange(value)}
                                    options={singleSelectOptions}
                                    placeholder="Select Column"
                                />

                            </Col>
                            <Col md='12' lg='4' className="flex-el-bottom-center">
                                <Input
                                    type="color"
                                    value={color}
                                    onChange={handleColorChange}
                                    className="custom-form-control-color"
                                />
                            </Col>
                            <Col md='12' lg='4' className="date-submit-center">
                                <div className='submit-button-row'>
                                    <Button className="btn-fill" disabled={singleSelect.value === "" ? true : false} color={mutationConfigCommandError ? "danger" : mutationConfigCommandSuccess ? "success" : 'info'} type="submit"
                                        onClick={(event) => handleSubmit(event)}
                                    >
                                        {mutationConfigCommandLoading ? <SuspenseComponentText /> :
                                            mutationConfigCommandError ? (
                                                <div>
                                                    Failure <i className="tim-icons icon-simple-remove" />
                                                </div>
                                            ) : mutationConfigCommandSuccess ? (
                                                <div>
                                                    Success <i className="tim-icons icon-check-2" />
                                                </div>
                                            ) :
                                                `Submit`
                                        }
                                    </Button>
                                </div>
                            </Col>

                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </>
    )
}

export default DeviceConfigCommandRemoteColour;