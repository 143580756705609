import React, { useState } from "react";
import PhoneInput from 'react-phone-input-2'
import {
    Card,
    CardBody,
    CardTitle,
    Row,
    Col,
    Button,
} from "reactstrap";
import SuspenseComponentText from '../../../views/components/SuspenseComponentText';
import useTimeout from "hooks/useTimeout";
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { postDeviceConfigCommand, getDeviceParameter } from "API";
import { commands } from "variables/commands";
import 'react-phone-input-2/lib/style.css'
// reactstrap components
const DeviceConfigCommandRemoteSupportNumber = ({ serial }) => {
    const [phoneCode, setPhoneCode] = useState("44");
    const [phoneNumber, setPhoneNumber] = useState('');
    const [currentPhoneNumber, setCurrentPhoneNumber] = useState('');
    const [country, setCountry] = useState("gb");

    const handlePhoneNumberChange = (value) => {
        setPhoneNumber(value);
    };

    const dataConfigSupportPhoteNumber = useQuery('ConfigSupportPhoteNumber', () => getDeviceParameter(serial, commands.support_phone_number), {
        refetchOnWindowFocus: false,
        onSuccess: (data) => {

            setCurrentPhoneNumber(data.data.value)

        }
    });

    const queryClient = useQueryClient()
    const { mutate: mutationConfigCommand, isLoading: mutationConfigCommandLoading, isError: mutationConfigCommandError, isSuccess: mutationConfigCommandSuccess, reset: mutationConfigCommandReset } = useMutation(
        {
            mutationFn: postDeviceConfigCommand,
            onSuccess: () => {
                // Invalidate and refetch
                timeoutClearDataPost();
                queryClient.invalidateQueries({ queryKey: ['Commands'] })
                queryClient.invalidateQueries({ queryKey: ['ConfigSupportPhoteNumber'] })

            },
            onError: () => {
                // Invalidate and refetch
                timeoutClearDataPost();
                queryClient.invalidateQueries({ queryKey: ['Commands'] })
            },
        }
    );

    const handleSubmit = (event) => {
        event.preventDefault();
        if (mutationConfigCommandError === true || mutationConfigCommandLoading === true) {
            return false
        }
        mutationConfigCommand({
            serial: serial,
            commandName: commands.support_phone_number,
            commandValue: phoneNumber
        })
    }

    const [timeoutClearDataPost] = useTimeout(() => {
        mutationConfigCommandReset();
    }, 3000);


    return (
        <>
            <Col lg="6" md="6">
                <Card className="card-stats">
                    <CardBody>
                        <Row>
                            <Col xs="3">
                                <div className="info-icon text-center icon-success">
                                    <i className="tim-icons icon-chat-33" />
                                </div>
                            </Col>
                            <Col xs="9">
                                <div className="numbers">
                                    <p className="card-category">Command</p>
                                    <CardTitle tag="h4">

                                        {currentPhoneNumber === "" ? 'Change Support Number' : `Change Support Number`} {currentPhoneNumber === "" ? "" : `: +${currentPhoneNumber}`}
                                    </CardTitle>
                                </div>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col md='12' lg='6' className="flex-el-bottom-center">
                                <PhoneInput
                                    country={country}
                                    enableSearch={false}
                                    countryCodeEditable={false}
                                    value={phoneNumber}
                                    onChange={handlePhoneNumberChange}
                                    autoFocus={false}
                                    disableCountryCode={false}
                                />
                            </Col>
                            <Col md='12' lg='6' className="date-submit-center">
                                <div className='submit-button-row'>
                                    <Button className="btn-fill" disabled={phoneNumber === "" ? true : false} color={mutationConfigCommandError ? "danger" : mutationConfigCommandSuccess ? "success" : 'info'} type="submit"
                                        onClick={(event) => handleSubmit(event)}
                                    >
                                        {mutationConfigCommandLoading ? <SuspenseComponentText /> :
                                            mutationConfigCommandError ? (
                                                <div>
                                                    Failure <i className="tim-icons icon-simple-remove" />
                                                </div>
                                            ) : mutationConfigCommandSuccess ? (
                                                <div>
                                                    Success <i className="tim-icons icon-check-2" />
                                                </div>
                                            ) :
                                                `Submit`
                                        }
                                    </Button>
                                </div>
                            </Col>

                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </>
    )
}

export default DeviceConfigCommandRemoteSupportNumber;