import React, { useState, useEffect } from 'react';
import { getDeviceRatings, getDeviceRatingsTEST } from "API";
import { useQuery } from 'react-query';
import SuspenseComponent from '../../../views/components/SuspenseComponent';
import ReactTable from "components/ReactTable/ReactTableConfig.js";
import moment from 'moment';
import { Bar } from "react-chartjs-2";
import * as XLSX from 'xlsx'; // Import XLSX as a namespace
import { saveAs } from 'file-saver';
import {
    Card,
    CardBody,
    CardTitle,
    Row,
    Col,
    CardHeader,
    Button
} from "reactstrap";
const RatingsTable = ({ valueDateRange, serial, loading }) => {


    const exportToCSV = () => {
        // Extract data from chartData
        const { labels, data } = csvData;

        // Prepare CSV content
        let csvContent = 'Label,Value\n';
        labels.forEach((label, index) => {
            const value = data[index]; // Assuming single dataset for simplicity
            csvContent += `"${label}","${value}"\n`;
        });

        // Convert to Blob
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

        // Save as CSV file
        saveAs(blob, 'chart_data.csv');
    };

    const initialState = {
        data: [
            {
                id: 1,
                '1': 0,
                '2': 0,
                '3': 0,
                '4': 0,
                '5': 0,
                actions: (
                    <div className="actions-right">
                    </div>
                )
            }
        ],
        csvData: {
            labels: ["Rating 1 😡", "Rating 2 😞", "Rating 3 🙂", "Rating 4 😄", "Rating 5 😍"],
            data: [65, 59, 80, 81, 56]
        }
    }

    const [data, setData] = useState(initialState.data);
    const [chartData, setChartData] = useState([]);
    const [csvData, setCsvData] = useState([]);
    const dataRatings = useQuery('RatingsData', () => {
        if (serial === null && valueDateRange[0] === null && valueDateRange[1] === null) {
            return getDeviceRatings();
        } else {
            return getDeviceRatings(valueDateRange[0], valueDateRange[1], serial)
        }
    }, {
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            setData([
                {
                    id: 1,
                    '1': data.data.ratings['1'],
                    '2': data.data.ratings['2'],
                    '3': data.data.ratings['3'],
                    '4': data.data.ratings['4'],
                    '5': data.data.ratings['5'],
                    actions: (
                        <div className="actions-right">
                        </div>
                    )
                }
            ])

        }
    });

    const getTrProps = (rowInfo) => {
        return {
            style: {
                background: 'green',
                color: 'white'
            }
        }
    }

    useEffect(() => {

        let csvExample3 = {
            labels: ["Rating 1 😡", "Rating 2 😞", "Rating 3 🙂", "Rating 4 😄", "Rating 5 😍"],
            data: [data[0]['1'], data[0]['2'], data[0]['3'], data[0]['4'], data[0]['5']]
        }

        let chartExample3 = {
            data: (canvas) => {
                let ctx = canvas.getContext("2d");

                let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

                gradientStroke.addColorStop(1, "rgba(72,72,176,0.1)");
                gradientStroke.addColorStop(0.4, "rgba(72,72,176,0.0)");
                gradientStroke.addColorStop(0, "rgba(119,52,169,0)"); //purple colors

                return {
                    labels: ["Rating 1 😡", "Rating 2 😞", "Rating 3 🙂", "Rating 4 😄", "Rating 5 😍"],
                    datasets: [
                        {
                            label: "Ratings",
                            fill: true,
                            backgroundColor: gradientStroke,
                            hoverBackgroundColor: gradientStroke,
                            borderColor: "#d048b6",
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            data: [data[0]['1'], data[0]['2'], data[0]['3'], data[0]['4'], data[0]['5']]
                        }
                    ]
                };
            },
            options: {
                maintainAspectRatio: false,
                legend: {
                    display: false
                },
                tooltips: {
                    backgroundColor: "#f5f5f5",
                    titleFontColor: "#333",
                    bodyFontColor: "#666",
                    bodySpacing: 4,
                    xPadding: 12,
                    mode: "nearest",
                    intersect: 0,
                    position: "nearest"
                },
                responsive: true,
                scales: {
                    yAxes: [
                        {
                            gridLines: {
                                drawBorder: false,
                                color: "rgba(225,78,202,0.1)",
                                zeroLineColor: "transparent"
                            },
                            ticks: {
                                suggestedMin: 60,
                                suggestedMax: 120,
                                padding: 20,
                                fontColor: "#9e9e9e"
                            }
                        }
                    ],
                    xAxes: [
                        {
                            gridLines: {
                                drawBorder: false,
                                color: "rgba(225,78,202,0.1)",
                                zeroLineColor: "transparent"
                            },
                            ticks: {
                                padding: 20,
                                fontColor: "#9e9e9e"
                            }
                        }
                    ]
                }
            }
        };

        setCsvData(csvExample3)
        setChartData(chartExample3)
    }, [data])


    return (
        <Row>
            <Col xs={12} md={12} lg={6}>
                <Card>
                    <CardHeader>
                        <CardTitle tag="h4">Ratings Table Data</CardTitle>
                    </CardHeader>
                    <CardBody>

                        {
                            loading || dataRatings.isLoading || dataRatings.isFetching ? <SuspenseComponent /> :
                                <div className='ratings-table'>

                                    <ReactTable
                                        data={data}
                                        filterable
                                        resizable={false}
                                        columns={[
                                            {
                                                Header: "Rating 1 😡",
                                                accessor: '1'
                                            },
                                            {
                                                Header: "Rating 2 😞",
                                                accessor: '2'
                                            },
                                            {
                                                Header: "Rating 3 🙂",
                                                accessor: '3'
                                            },
                                            {
                                                Header: "Rating 4 😄",
                                                accessor: '4'
                                            },
                                            {
                                                Header: "Rating 5 😍",
                                                accessor: '5'
                                            },
                                            {
                                                Header: "",
                                                accessor: "actions",
                                                sortable: false,
                                                filterable: false
                                            }

                                        ]}
                                        defaultPheartbeatSize={10}
                                        showPaginationTop
                                        showPaginationBottom={false}
                                        getTrProps={getTrProps}
                                        className="-striped -highlight"
                                    />
                                </div>
                        }


                    </CardBody>
                </Card>
            </Col>
            <Col lg={6}>
                <Card className="card-chart">
                    <CardHeader>
                        <h5 className="card-category">Ratings Plot Data</h5>
                        <Button className="btn-fill" color="primary" type="button" onClick={() => exportToCSV()}>
                        Export to CSV
                        </Button>
                    </CardHeader>
                    <CardBody>
                        <div className="chart-area">
                            <Bar
                                data={chartData.data}
                                options={chartData.options}
                            />
                        </div>
                    </CardBody>
                </Card>
            </Col>

        </Row>
    )
}

export default RatingsTable;