import React, { useContext, useState, useEffect } from "react";
import { useQuery, useQueryClient } from "react-query";
import { intervalGetDeviceDataAccess } from 'API';
import { useDataDeviceAccessUpdate, useIntervalDataDeviceAccessUpdate, useIntervalDataDeviceAccess } from "./DataDeviceContext";

import { useReducer } from 'react';

const initialState = { first: false };

function reducer(state, action) {
    switch (action.type) {
        case 'add':
            return { first: true };
        default:
            throw new Error();
    }
}

const IntervalDataContext = React.createContext()
const IntervalDataUpdateContext = React.createContext()

export function useIntervalAccess() {
    return useContext(IntervalDataContext)
}
export function useIntervalUpdate() {
    return useContext(IntervalDataUpdateContext)
}
export function IntervalDataProvider({ children }) {

    const changeDataDeviceAccess = useDataDeviceAccessUpdate();
    const changeIntervalDataDeviceAccess = useIntervalDataDeviceAccessUpdate();
    const userIntervalDataDeviceAccess = useIntervalDataDeviceAccess();
    const queryClient = useQueryClient()
    const queryKey = ['intervalDeviceDataAccess'];

    const { data, isLoading, error } = useQuery(queryKey, intervalGetDeviceDataAccess, {
        // Set the interval to 60 seconds
        refetchInterval: 60000,
        // Only refetch the data if the value of the 'variable' in local storage changes
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnSuspense: false,
        onSuccess: (data) => {
            // Invalidate and refetch

            if (data !== undefined) {
                changeDataDeviceAccess(data.data.serial_list);
            }
        },
    });
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        
        if (state.first === false) {
            dispatch({ type: 'add' });
            queryClient.refetchQueries({ queryKey: queryKey });
        }
    }, [state.first]);

    return (
        <IntervalDataContext.Provider value={{ state, dispatch }}>
            <IntervalDataUpdateContext.Provider value={true}>
                {children}
            </IntervalDataUpdateContext.Provider>
        </IntervalDataContext.Provider>
    )
}


