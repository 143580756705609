import React, { useState } from 'react'
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from '@react-google-maps/api';

const containerStyle = {
    width: '100%',
    height: '857px',
    borderRadius: '5px'
};

const center = {
    lat: 51,
    lng: 21
};

const MAP_OPTIONS = {
    scrollwheel: false,
}


function DeviceInfoGoogleMap2({ latitudeValue, longitudeValue, deviceSerial }) {
    const [activeMarker, setActiveMarker] = useState(null);

    const handleActiveMarker = (marker) => {
        if (marker === activeMarker) {
            return;
        }
        setActiveMarker(marker);
    };

    const centerData = {
        lat: latitudeValue === 0,
        lng: longitudeValue
    }

    const handleCenterData = (elem) => {
        if (elem === 'lat') {
            let lat;
            if (latitudeValue === '') {
                lat = 51
            }
            else {
                lat = Number(latitudeValue)
            }
            return lat
        }
        else if (elem === 'lng') {
            let lng;
            if (longitudeValue === '') {
                lng = 51
            }
            else {
                lng = Number(longitudeValue)
            }
            return lng
        }
        else {
            let lat, lng;
            if (latitudeValue === '') {
                lat = 51
            }
            else {
                lat = Number(latitudeValue)
            }
            if (longitudeValue === '') {
                lng = 51
            }
            else {
                lng = Number(longitudeValue)
            }
            return { lat: lat, lng: lng }
        }

    }

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_CUSTOM_GOOGLE_API_KEY
    })

    const [map, setMap] = React.useState(null);

    const onLoad = React.useCallback(function callback(map) {

        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])


    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={handleCenterData()}
            zoom={7}
            onUnmount={onUnmount}
            options={MAP_OPTIONS}
        >
            { /* Child components, such as markers, info windows, etc. */}
            {longitudeValue === '' || latitudeValue === '' ?
                null :
                <Marker
                    onLoad={onLoad}
                    position={handleCenterData()}
                    clickable={true}
                    onClick={() => handleActiveMarker(1)}
                >
                    {activeMarker === 1 ? (
                        <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                            <div>
                                <a target={`_blank`} href={`https://www.google.com/maps/search/?api=1&query=${handleCenterData('lat')},${handleCenterData('lng')}`}>Directions </a>
                                to {deviceSerial}
                            </div>
                        </InfoWindow>
                    ) : null}
                </Marker>
            }
        </GoogleMap>
    ) : <></>
}

export default React.memo(DeviceInfoGoogleMap2)